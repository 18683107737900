import LoginForm from "../components/loginForm";
import PageNotFound from "../components/pageNotFound";
import {
  importPageRoute,
  unauthorizedRoute,
  resetPasswordRoute,
  creationMembershipCardRoute,
  batchPrintRoute,
  pushNotificationRoute,
  membershipManagementRoute,
  reportsRoute,
  assignCardsRoute,
  userGroupAdminRoute,
  settingsRoute,
  logsRoute,
  newLogsRoute
} from "./routeRules";
import Unauthorized from "../sharedComponents/unauthorized";
import ResetPassword from "../components/resetPassword";
import ImportPage from "../components/importPage";
import CreationMembershipCard from "../components/CreationMembershipCard";
import SendPushNotifications from "../components/SendPushNotifications";
import MembershipManagement from "../components/MembershipManagement";
import Reports from "../components/Reports";
import UserGroup from "../components/UserGroup";
import Settings from "../components/Settings";
import AssignCards from "../components/assignCards";
import BatchPrint from "../components/importPage/BatchPrint";
import Logs from "../components/Logs";
import newLogs from "../components/newLogs";
import Upload from "../components/uploads";

const routes = [
  {
    path: "/",
    exact: true, // this will match the exact url

    // authorization is use to check public private route
    // authorization = false then it is public route and anyone can access this path
    // authorization = true then it is private route and only logged in user can access this path
    authorization: false,
    // if websiteLayout = true then it will display header, footer , sidebar etc.
    // if websiteLayout =  false then it will not display header, footer , sidebar etc.
    websiteLayout: false,
    component: LoginForm,
  },
  {
    path: importPageRoute,
    permission: ["Administrator"],
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: ImportPage,
  },
  {
    path: creationMembershipCardRoute,
    permission: ["Administrator"],
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: CreationMembershipCard,
  },
  {
    path: pushNotificationRoute,
    permission: ["Administrator"],
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: SendPushNotifications,
  },
  {
    path: reportsRoute,
    permission: ["Administrator"],
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: Reports,
  },
  {
    path: membershipManagementRoute,
    permission: ["Administrator"],
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: MembershipManagement,
  },
  {
    path: settingsRoute,
    permission: ["Administrator"],
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: Settings,
  },
  {
    path: assignCardsRoute,
    permission: ["Administrator"],
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: AssignCards,
  },
  {
    path: logsRoute,
    permission: ["Administrator"],
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: Logs,
  },
  {
    path: newLogsRoute,
    permission: ["Administrator"],
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: newLogs,
  },
  {
    path: userGroupAdminRoute,
    permission: ["Administrator"],
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: UserGroup,
  },
  {
    path: batchPrintRoute,
    exact: true,
    authorization: true,
    websiteLayout: true,
    component: BatchPrint,
  },
  {
    path: unauthorizedRoute,
    exact: true,
    authorization: true,
    websiteLayout: false,
    component: Unauthorized,
  },
  {
    path: resetPasswordRoute,
    exact: true,
    authorization: false,
    websiteLayout: false,
    component: ResetPassword,
  },

  {
    path: "/uploads",
    exact: true,
    component: Upload,
  },
  {
    path: "*", // this will execute when above path doesn't matches
    authorization: false,
    websiteLayout: false,
    component: PageNotFound,
  },
];
export default routes;
