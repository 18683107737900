import React from 'react';
import { Route, Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Layout from './siteStructure';

const PublicRoute = ({ component: Component, websiteLayout, ...rest }) => {
  const history = useHistory();

  const loginAPIResponse = useSelector(
    state => state.authentication.loginAPIResponse
  );

  return (
    <Route
      {...rest}
      render={props =>
        loginAPIResponse &&
        'data' in loginAPIResponse &&
        'data' in loginAPIResponse.data &&
        'success' in loginAPIResponse.data &&
        loginAPIResponse.data.success === true &&
        // &&
        // history.location.pathname === '/' ? (
        //   <Redirect
        //     to={{
        //       pathname: '/import',
        //       state: { from: props.location }
        //     }}
        //   />
        // )
        websiteLayout ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
