import React, { useRef, useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { batchPrintRoute } from "../../routes/routeRules";
import {
  uploadUsersFile,
  exportFile,
  getFileUploadHistory,
} from "../../redux/import-export";
import {
  setSelectedUsersForPrint,
  setSideBarItemKey,
  uploadUsersFileResponse,
} from "../../redux/import-export/action";
import {
  Layout,
  Typography,
  Upload,
  message,
  Button,
  Row,
  Col,
  Skeleton,
  Checkbox,
  Timeline,
  Empty,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { isArray } from "util";
const { Text } = Typography;

const { Dragger } = Upload;

const ImportPage = () => {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const didMountDownloadRef = useRef(false);
  const didMountHistoryRef = useRef(false);

  const history = useHistory();

  // ******** State ********* //

  const [loading, setLoading] = useState(false);
  const [usersData, setUsersData] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [uploadUsersFileResponseStateDataLength, setUploadUsersFileResponseStateDataLength] = useState({updatedEntryDateCount:0,updatedPrivateMobileCount:0,updatedUserCount:0,newUserCount:0});

  // ******** Redux State ********* //

  const uploadUsersFileResponseState = useSelector(
    (state) => state.importExport.uploadUsersFileResponse
  );

  const exportFileResponse = useSelector(
    (state) => state.importExport.exportFileResponse
  );

  const historyDataResponse = useSelector(
    (state) => state.importExport.historyDataResponse
  );

  const selectedUsersForPrint = useSelector(
    (state) => state.importExport.selectedUsersForPrint
  );

  // ******** Handlers ********* //

  const handleChangeFileUpload = (info) => {
    console.log({info})
    if (info.file.status === "uploading") {
      // setImageUploadLoading(true);
      return;
    } else if (info.file.status === "done") {
      message.success(`${info.file.name}  erfolgreich hochgeladen.`);
    } else if (info.file.status === "error") {
      // setImageUploadLoading(false);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleBeforeFileUpload = (file) => {
    console.log({file})
    const isXlxsOrXls =
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "xls";
    if (!isXlxsOrXls) {
      message.error("You can only upload xlxs/xls file");
    }
    return isXlxsOrXls;
  };

  const onChangeCheckbox = (e, type, index) => {
    let tempData = [];

    if (type === "newUser") {
      tempData = [...uploadUsersFileResponseState.data.newUser];
    }

    if (type === "updatedUser") {
      tempData = [...uploadUsersFileResponseState.data.updatedUser];
    }

    tempData[index].checked = !tempData[index].checked;

    const data = {
      ...uploadUsersFileResponseState,
    };
    handleSetSelectedUsers(data);
    dispatch(uploadUsersFileResponse(data));
  };

  const handleSetSelectedUsers = (data) => {
    if (data) {
      let checkedNewUserData =
        "newUser" in data.data &&
        isArray(data.data.newUser) &&
        data.data.newUser.length > 0
          ? data.data.newUser.filter((user) => {
              return user.checked === true;
            })
          : [];

      let checkedChangedUserNameData =
        "updatedUser" in data.data &&
        isArray(data.data.updatedUser) &&
        data.data.updatedUser.length > 0
          ? data.data.updatedUser.filter((user) => {
              return user.checked === true;
            })
          : [];

      let allCheckedUsers = [];

      if (
        checkedNewUserData &&
        isArray(checkedNewUserData) &&
        checkedNewUserData.length > 0
      ) {
        allCheckedUsers = [...checkedNewUserData];
      }

      if (
        checkedChangedUserNameData &&
        isArray(checkedChangedUserNameData) &&
        checkedChangedUserNameData.length > 0
      ) {
        if (isArray(allCheckedUsers) && allCheckedUsers.length > 0) {
          allCheckedUsers = [...allCheckedUsers, ...checkedChangedUserNameData];
        } else {
          allCheckedUsers = [...checkedChangedUserNameData];
        }
      }

      dispatch(setSelectedUsersForPrint(allCheckedUsers));
    }
  };

  const onChangeSelectAll = (e, type) => {
    let isChecked = e.target.checked;
    let tempData = [];

    let isCheckedNewUsers;
    let isCheckedUpdateUser;

    if (type === "newUser") {
      let tempNewusers = [...uploadUsersFileResponseState.data.newUser];

      tempData = tempNewusers.map((user) => ({
        ...user,
        checked: isChecked,
      }));
    }

    if (type === "updatedUser") {
      let tempChangedUserName = [
        ...uploadUsersFileResponseState.data.updatedUser,
      ];
      tempData = tempChangedUserName.map((user) => ({
        ...user,
        checked: isChecked,
      }));
    }

    const data = {
      ...uploadUsersFileResponseState,
      data: {
        ...uploadUsersFileResponseState.data,
        selectAllChangedUserName:
          type === "updatedUser"
            ? isChecked
            : uploadUsersFileResponseState.data.selectAllChangedUserName,
        selectAllNewUser:
          type === "newUser"
            ? isChecked
            : uploadUsersFileResponseState.data.selectAllNewUser,
        [type]: tempData,
      },
    };

    dispatch(uploadUsersFileResponse(data));
    handleSetSelectedUsers(data);
  };

  const onClickNextBtn = () => {
    dispatch(uploadUsersFileResponse(null));

    history.push(batchPrintRoute);
  };

  // const handleExportFile = () => {
  //   dispatch(exportFile());
  // };

  const handleDownloadFile = (docURL) => {
    window.open(`${docURL}`);
  };

  // ******** Effects ********* //

  useEffect(() => {
    dispatch(setSelectedUsersForPrint(null));
  }, []);

  useEffect(() => {
    if (didMountRef.current) {
      setLoading(false);
      if (uploadUsersFileResponseState) {
        console.log("uploadUsersFileResponseState : ", {uploadUsersFileResponseState})
        if (uploadUsersFileResponseState.status === 200) {
          const data = uploadUsersFileResponseState.data;
          // if()
          let dataCount = {updatedEntryDateCount:0,updatedPrivateMobileCount:0,updatedUserCount:0,newUserCount:0};
          if(uploadUsersFileResponseState && "data" in uploadUsersFileResponseState){
            dataCount.newUserCount = ("newUser" in uploadUsersFileResponseState.data && uploadUsersFileResponseState.data.newUser.length && uploadUsersFileResponseState.data.newUser.length > 0) ? uploadUsersFileResponseState.data.newUser.length : 0;
            dataCount.updatedUserCount = ("updatedUser" in uploadUsersFileResponseState.data && uploadUsersFileResponseState.data.updatedUser.length && uploadUsersFileResponseState.data.updatedUser.length > 0) ? uploadUsersFileResponseState.data.updatedUser.length : 0;
            dataCount.updatedPrivateMobileCount = ("updatedPrivateMobile" in uploadUsersFileResponseState.data && uploadUsersFileResponseState.data.updatedPrivateMobile.length && uploadUsersFileResponseState.data.updatedPrivateMobile.length > 0) ? uploadUsersFileResponseState.data.updatedPrivateMobile.length : 0;
            dataCount.updatedEntryDateCount = ("updatedEntryDate" in uploadUsersFileResponseState.data && uploadUsersFileResponseState.data.updatedEntryDate.length && uploadUsersFileResponseState.data.updatedEntryDate.length > 0) ? uploadUsersFileResponseState.data.updatedEntryDate.length : 0;
          }
          setUploadUsersFileResponseStateDataLength(dataCount);
          console.log("File Successfully uploaded.");
          // if (uploadUsersFileResponseState.statusText === "OK") {
          //   console.log("File Successfully uploaded.");
          // } else {
          //   message.error("An error has ocurred");
          // }
        } else {
          message.error(uploadUsersFileResponseState.message);
        }
      }
    }

    didMountRef.current = true;
  }, [uploadUsersFileResponseState]);

  useEffect(() => {
    if (didMountHistoryRef.current) {
      setLoading(false);

      if (historyDataResponse) {
        if (historyDataResponse.status === 200) {
          const data = historyDataResponse.data;

          if (data.success) {
            setHistoryData(data.data);
          } else {
            message.error(data.message);
          }
        }
      } else {
        message.error(historyDataResponse.message);
      }
    } else {
      dispatch(getFileUploadHistory());
      didMountHistoryRef.current = true;
    }
  }, [historyDataResponse]);

  useEffect(() => {
    if (didMountDownloadRef.current) {
      setLoading(false);
      if (exportFileResponse) {
        if (exportFileResponse.status === 200) {
          const data = exportFileResponse.data;

          if (data.success) {
            handleDownloadFile(data.data.url);
          } else {
            message.error(data.message);
          }
        } else {
          message.error(exportFileResponse.message);
        }
      }
    }
    didMountDownloadRef.current = true;
  }, [exportFileResponse]);

  return (
    <Fragment>
      <Row justify="center">
        <Col span={12}>
          <h2 className="text-align-center">
            <Text strong>Import Mitgliederdaten</Text>
          </h2>
        </Col>
      </Row>
      <Row>
        <Col span={12} style={{ padding: "40px" }}>
          <Dragger
            beforeUpload={handleBeforeFileUpload}
            customRequest={(params) => {
              setLoading(true);
              dispatch(
                uploadUsersFile(params.file, params.onSuccess, params.onError)
              );
            }}
            onChange={(info) => handleChangeFileUpload(info)}
            multiple={false}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <PlusOutlined />
            </p>
            <p className="ant-upload-text">
              Zum Hochladen  einer Excel Datei Feld anklicken oder Datei per
              Drag & Drop einfügen
            </p>
          </Dragger>
          <br />
          <br />
          <h3>Historie</h3>
          <br />
          <Timeline>
            {historyData &&
              isArray(historyData) &&
              historyData.length > 0 &&
              historyData.map((data) => (
                <Timeline.Item>
                  {new Date(data.updatedAt).toLocaleString()}
                </Timeline.Item>
              ))}
          </Timeline>
        </Col>
        <Col span={12}>
          <br />
          <br />
          <br />
          <Fragment>
            <h2> Ergebnis letzer Upload</h2>
            <br />
            <h4>Neue Mitglieder EFSC</h4>
            <h4>
              Anzahl : {/* my code */}
              {uploadUsersFileResponseStateDataLength.newUserCount}
            </h4>

            {loading ? (
              <Skeleton active />
            ) : (
              <Fragment>
                <div className="select-all-checkbox">
                  {uploadUsersFileResponseStateDataLength.newUserCount > 0 ? (
                    <Checkbox
                      checked={
                        uploadUsersFileResponseState.data.selectAllNewUser
                      }
                      onChange={(e) => onChangeSelectAll(e, "newUser")}
                    >
                      Alle auswählen
                    </Checkbox>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  <div className="select-all-scroll">
                    {uploadUsersFileResponseStateDataLength.newUserCount > 0 ? (
                      uploadUsersFileResponseState.data.newUser.map(
                        (user, index) => {
                          return (
                            <div>
                              <Checkbox
                                checked={user.checked}
                                onChange={(e) =>
                                  onChangeCheckbox(e, "newUser", index)
                                }
                              >
                                {user.firstname} {user.surname}
                              </Checkbox>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
            <br />
            <h4> Bestehende Mitglieder EFSC mit aktualisierten Namen</h4>
            <h4>
              Anzahl :{" "}
              {uploadUsersFileResponseStateDataLength.updatedUserCount > 0
                ? uploadUsersFileResponseStateDataLength.updatedUserCount
                : 0}
            </h4>
            {loading ? (
              <Skeleton active />
            ) : (
              <Fragment>
                <div className="select-all-checkbox">
                  {uploadUsersFileResponseStateDataLength.updatedUserCount > 0 ? (
                    <Checkbox
                      checked={
                        uploadUsersFileResponseState.data
                          .selectAllChangedUserName
                      }
                      onChange={(e) => onChangeSelectAll(e, "updatedUser")}
                    >
                      Alle auswählen
                    </Checkbox>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  <div className="select-all-scroll">
                    {uploadUsersFileResponseStateDataLength.updatedUserCount > 0 ? (
                      uploadUsersFileResponseState.data.updatedUser.map(
                        (user, index) => {
                          return (
                            <div>
                              <Checkbox
                                checked={user.checked}
                                onChange={(e) =>
                                  onChangeCheckbox(e, "updatedUser", index)
                                }
                              >
                                {user.firstname} {user.surname}
                              </Checkbox>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
            <br />
            {uploadUsersFileResponseState &&
            selectedUsersForPrint &&
            isArray(selectedUsersForPrint) &&
            selectedUsersForPrint.length > 0 ? (
              <Button type="primary" onClick={onClickNextBtn}>
                Nächster Schritt
              </Button>
            ) : (
              <Button type="primary" disabled={true}>
                Nächster Schritt
              </Button>
            )}
            <br />
            <br />
            <h4>Aktualisierte Handynummer</h4>
            <h4>
              Anzahl :{" "}
              {uploadUsersFileResponseStateDataLength.updatedPrivateMobileCount > 0
                ? uploadUsersFileResponseStateDataLength.updatedPrivateMobileCount
                : 0}
            </h4>


            {loading ? (
              <Skeleton active />
            ) : (
              <Fragment>
                <div className="select-all-checkbox">
                  <div className="select-all-scroll">
                    {uploadUsersFileResponseStateDataLength.updatedPrivateMobileCount > 0 ? (
                      uploadUsersFileResponseState.data.updatedPrivateMobile.map(
                        (user) => {
                          return (
                            <div>
                              {user.firstname} {user.surname}
                            </div>
                          );
                        }
                      )
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
            <br />
            <h4>Aktualisierter Eintrag Austrittsdatum</h4>
            <h4>
              Anzahl :{" "}
              {uploadUsersFileResponseStateDataLength.updatedEntryDateCount > 0
                ? uploadUsersFileResponseStateDataLength.updatedEntryDateCount
                : 0}
            </h4>
            
            {loading ? (
              <Skeleton active />
            ) : (
              <Fragment>
                <div className="select-all-checkbox">
                  <div className="select-all-scroll">
                    {uploadUsersFileResponseStateDataLength.updatedEntryDateCount >
                      0 ? (
                      uploadUsersFileResponseState.data.updatedEntryDate.map(
                        (user) => {
                          return (
                            <div>
                              {user.firstname} {user.surname}
                            </div>
                          );
                        }
                      )
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                </div>
              </Fragment>
            )}
            <br />
          </Fragment>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ImportPage;
