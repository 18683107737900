import React, { useRef, useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { autheader } from "../../functions/users";

import { API_VERSION } from "../../functions/users";
import {
  Row,
  Col,
  Table,
  Checkbox,
  Typography,
  Modal,
  TimePicker,
  Button,
  message,
  Input,
  Switch,
  Space,
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import moment from "moment";
const format = "HH:mm";

const { TextArea } = Input;

const { Title, Text } = Typography;

const Settings = () => {
  const [openingHours, setOpeningHours] = useState("");
  const [capacity, setCapacity] = useState("");
  const [maxcapacity, setMaxcapacity] = useState("");
  const [buffer, setBuffer] = useState("");

  const [status, setStatus] = useState(true);

  const [start, setStart] = useState({});
  const [startvalue, setStartvalue] = useState(null);
  const [endvalue, setEndvalue] = useState(null);
  const [end, setEnd] = useState({});

  function countDown(message) {
    let secondsToGo = 1;
    const modal = Modal.success({
      title: message,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }
  const checkMaxCapacity = (event) => {
    setMaxcapacity(event.target.value);
  };

  const checkBuffer = (event) => {
    console.log(event.target.value);
    setBuffer(event.target.value);
  };

  const checkStart = (value) => {
    setStartvalue(value);
    let hour = new Date(value._d).getHours();
    let minute = new Date(value._d).getMinutes();

    setStart({ hour, minute });
  };

  const checkEnd = (value) => {
    setEndvalue(value);
    let hour = new Date(value._d).getHours();
    let minute = new Date(value._d).getMinutes();
    setEnd({ hour, minute });
  };
  function minutesToHoursAndMinute(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    rhours = rhours < 10 ? `0${rhours}` : rhours;
    rminutes = rminutes < 10 ? `0${rminutes}` : rminutes;
    return rhours + ":" + rminutes;
  }
  useEffect(() => {
    async function getOpeningHours() {
      const { Authorization } = autheader();

      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/get-opening-hours`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization,
            "Content-Type": "application/json",
          },
        }
      );

      let data = await response.json();
      let startH = minutesToHoursAndMinute(data.start);

      let endH = minutesToHoursAndMinute(data.end);

      setOpeningHours({
        start: startH,
        end: endH,
        _id: data._id,
      });
    }

    getOpeningHours();
  }, [openingHours]);
  useEffect(() => {
    async function getCapacityAndBuffer() {
      const { Authorization } = autheader();

      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/get-capacity-buffer`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization,
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();
      setBuffer(data.buffer);
      setMaxcapacity(data.maxCapacity);
      setCapacity({
        _id: data._id,
        maxcapacity: data.maxCapacity,
        buffer: data.buffer,
      });
      setStatus(data.status);
    }
    getCapacityAndBuffer();
  }, []);
  const updateCapacity = async (v) => {
    let data = {
      _id: capacity._id,
      maxCapacity: maxcapacity,
      buffer: buffer,
    };

    if (data.maxCapacity === "" || data.buffer === "") {
      return alert("please insert value ");
    }
    try {
      const { Authorization } = autheader();

      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/update-capacity-buffer`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        countDown("Maximale Kapazität und Puffer aktualisiert");
      }
    } catch (error) {
      throw error;
    }
  };
  const onChangeStatus = async (checked) => {
    let data = {
      _id: capacity._id,
      status: checked,
    };
    try {
      const { Authorization } = autheader();

      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/update-lake-status`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        setStatus(checked);
        countDown("Seestatus geändert");
      }
    } catch (error) {
      throw error;
    }
  };
  const changeOpeningHour = async (v) => {
    let startminute = start.hour * 60 + start.minute;
    let endminute = end.hour * 60 + end.minute;
    let data = {
      start: startminute,
      end: endminute,
      _id: openingHours._id,
    };
    if (endminute <= startminute) {
      return alert("invalid time selection");
    }
    try {
      const { Authorization } = autheader();

      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/update-opening-hours`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        countDown("Erfolgreich geänderte Öffnungszeiten");
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <Fragment>
      <h2 className="text-align-center">
        <Text strong>Einstellungen</Text>
      </h2>
      <Row>
        <Col>
          <Title level={4}>Einstellungen ändern</Title>
          <Space direction="horizontal">
            <Text strong>Öffnungszeit</Text>
            Von
            <TimePicker
              // defaultValue={moment(`${openingHours.start}`, format)}
              format={format}
              value={startvalue}
              onChange={checkStart}
            />
            Bis
            <TimePicker
              // defaultValue={moment(`${openingHours.end}`, format)}
              format={format}
              value={endvalue}
              onChange={checkEnd}
            />
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={changeOpeningHour}
            >
              Aktualisieren
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text>
            Aktuelle Öffnungszeiten von {openingHours.start} bis &nbsp;
            {openingHours.end}
          </Text>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col>
          <Space direction="horizontal">
            <Text strong>maximale Kapazität</Text>
            <Input
              placeholder="Maximale Kapazität einstellen"
              value={maxcapacity}
              onChange={checkMaxCapacity}
            />
          </Space>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Space direction="horizontal">
            <Text strong>Puffer</Text>
            <Input
              placeholder="Puffergröße einstellen"
              value={buffer}
              onChange={checkBuffer}
            />
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={updateCapacity}
            >
              Aktualisieren
            </Button>
          </Space>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Space direction="horizontal">
            <Text strong>Seestatus</Text>
            <Switch checked={status} onChange={onChangeStatus} />
          </Space>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Settings;
