import React, { useRef, useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { autheader } from "../../functions/users";

import { API_VERSION, searchUser } from "../../functions/users";

import {
  Row,
  Col,
  Select,
  DatePicker,
  Space,
  Typography,
  Card,
  Radio,
  Spin,
  Button,
  Table,
  message,
  TimePicker,
  Statistic,
  Input,
  Checkbox,
  Modal,
  Divider,
  notification,
} from "antd";
import moment from "moment";
import {
  SearchOutlined,
  CheckOutlined,
  LoadingOutlined,
} from "@ant-design/icons";


const { confirm } = Modal;

const { Text, Title } = Typography;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const { RangePicker } = DatePicker;
const format = "HH:mm";

const NewLogs = () => {
  const [loading, setLoading] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [dateValue, setDateValue] = useState();
  const [paginationCount, setPaginationCount] = useState(1);

  
  const dispatch = useDispatch();
  const dateFormat = "MM/DD/YYYY";

  const searchQueryFunction = () => {
   
    setLoading(true);
    let date = '';
    if (dateValue !== null) {
      date = moment(dateValue).format("YYYY-MM-D");
    }
    const { Authorization } = autheader();

    fetch(
      `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/userTrackListing?page=1&date=${date}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        let logs = data;
        setLoading(false);
        if (logs) {
          setLoading(false);
          const pager = { ...pagination };
          let page = pager.current;
          if (page === undefined) {
            page = 1;
          }
         
          const list = logs.data;
          
          let dataAry = [];
    
          for (let i = 0; i < list.length; i++) {
            let newList = {};
            newList['time'] = list[i].createdAt;
            newList['date'] = list[i].date;
            newList['firstname'] = list[i].firstname;
            newList['status'] = list[i].status;
            newList['mediaType'] = list[i].mediaType;
            newList['message'] = list[i].message;
            newList['memberNo'] = list[i].mediaData['member_no'];
            dataAry.push(newList);
          }
          
          setLogsData(dataAry);
          const paginationData = { ...pagination };
          // Read total count from server
        
          const totalPage = logs.totalRecords;
          
          setPaginationCount(totalPage);
        
          setPagination(paginationData);
        }
      });
  };

  const tableColumns = [
    { title: "Name", dataIndex: "firstname", key: "firstname" },
    { title: "Mitgliedsnummer", dataIndex: "memberNo", key: "memberNo" },
    { title: " Via", dataIndex: "mediaType", key: "mediaType"},
    { title: "Status", dataIndex: "status",  key: "status" },
    {
      title: "Datum",
      dataIndex: "date",
      key: "date",
      render: (text, row, index) => {
        if (row.date) {
          // console.log(row.date);
         
          let localDate = new Date(row.date).toLocaleDateString();
          return localDate;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      // key: "date",
      render: (text, row, index) => {
        if (row.date) {
          // console.log(row.date);
         
          let localDate = new Date(row.date).toLocaleTimeString();
          return localDate;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {},
  ];
  const handleTableChange = (selectedPagination) => {
    const pager = { ...pagination };
    
    pager.current = selectedPagination.current;
    const page = selectedPagination.current;
    let date1 = '';
   
    if (dateValue !== null && dateValue !== undefined) {
      date1 = moment(dateValue).format("YYYY-MM-D");
    }

    setLoading(true);
    setPagination(pager);
    fetchLogs(page,date1);
  };
  async function fetchLogs(page1,date='') { 
    const { Authorization } = autheader();
   
    let response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/userTrackListing?page=${page1}&date=${date}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
      }
    );
    let logs = await response.json();
   
    if (logs) {
      setLoading(false);
      const pager = { ...pagination };
      let page = pager.current;
      if (page === undefined) {
        page = 1;
      }
     
      const list = logs.data;
      
      let dataAry = [];

      for (let i = 0; i < list.length; i++) {
        let newList = {};
        newList['date'] = list[i].createdAt;
        newList['firstname'] = list[i].firstname;
        newList['status'] = list[i].status;
        newList['mediaType'] = list[i].mediaType;
        newList['message'] = list[i].message;
        newList['memberNo'] = list[i].mediaData['member_no'];
        dataAry.push(newList);
      }
      
      setLogsData(dataAry);
      const paginationData = { ...pagination };
      // Read total count from server
    
      const totalPage = logs.totalRecords;
      
      setPaginationCount(totalPage);
    
      setPagination(paginationData);
    }
    
  }
  
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  
  useEffect(() => {
    fetchLogs(1,'');
  }, []);
  return (
    <Fragment>
      {/* <Row>
        <Col span={24}>
          <h2 className="text-align-center">
            <Text strong>New Logs</Text>
          </h2>
        </Col>
      </Row> */}
      <Row>
        <Col span={6}>
          <DatePicker
            format={dateFormat}
            onChange={(val) => setDateValue(val)}
          />
        </Col>
        <Col span={3}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={searchQueryFunction}
          >
            Suchen
          </Button>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <Table
          columns={tableColumns}
          loading={loading}
          dataSource={logsData}
          onChange={handleTableChange}
          pagination={{
            pageSize: 10,
            total: paginationCount,
            showSizeChanger: false,
          }}
        />
      </Spin>
    </Fragment>
  );
};

export default NewLogs;
