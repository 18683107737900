import React, { useState } from "react";
import axios from "axios";

const Upload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const onChangeHandler = (event) => {
    const files = event.target.files[0];
    setSelectedFile(files);
  };

  const onClickHandler = () => {
    const data = new FormData();
    data.append("uploadfile", selectedFile);
    axios
      .post(`${process.env.REACT_APP_SERVER_URI}/api/excel-uploads`, data, {
        onUploadProgress: (ProgressEvent) => {
          console.log(ProgressEvent);
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickUserHandler = () => {
    const data = new FormData();
    data.append("uploadfile", selectedFile);
    axios
      .post(`${process.env.REACT_APP_SERVER_URI}/api/user-data`, data, {
        onUploadProgress: (ProgressEvent) => {
          console.log(ProgressEvent);
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="offset-md-3 col-md-6">
          <div className="form-group files">
            <label>RFID Upload Your File </label>
            <input
              type="file"
              className="form-control"
              onChange={onChangeHandler}
            />
          </div>
          <button
            type="button"
            className="btn btn-success btn-block"
            onClick={onClickHandler}
          >
            RFID Upload
          </button>
          <button
            type="button"
            className="btn btn-success btn-block"
            onClick={onClickUserHandler}
          >
            User Data Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default Upload;
