import React, { Fragment } from 'react';
import { Modal, Image, Button, Row, Col, Typography } from 'antd';
import bgimage from '../../../assets/pexels-matthias-cooper.jpg';

const { Text } = Typography;
const CardPreview = props => {
  const { visible, setVisibleCardPreviewModal } = props;

  return (
    <Fragment>
      <Modal
        title="Erstellung Mitgliedsausweise"
        visible={visible}
        onCancel={() => setVisibleCardPreviewModal(false)}
        footer={
          <Button
            type="primary"
            onClick={() => {
              setVisibleCardPreviewModal(false);
            }}
          >
            Close
          </Button>
        }
        width="80%"
        height="80%"
      >
        <Row
          style={{ backgroundImage: `url(${bgimage})` }}
          className="pop-up-card-preview"
        >
          <Col span={24}>
            <div className="pop-up-card-preview-col">
              <h1>2020</h1>
              <img src={process.env.PUBLIC_URL + '/assets/Logo.png'} />
              <h2>ERSTER FRANKFURTER SCHWIMM CLUB</h2>
              <p>Von 1891 e.V. (EFSC)</p>
            </div>
          </Col>
          <Col span={24}>
            <div className="pop-up-card-preview-col-bottom">
              <div>Max Mustermann</div>
              <div>012345678</div>
            </div>
          </Col>
        </Row>

        <br />

        <Row
          style={{ backgroundImage: `url(${bgimage})` }}
          className="pop-up-card-preview popup-preview"
        >
          <Col span={24}>
            <div className="list-card-preview">
              <ul>
                <li>069 / 904 371 05</li>
                <li>www.efsc.de</li>
                <li>efsc@schwimmzentrum-frankfurt.de</li>
                <li>Rhönstraße 117, 60385 Frankfurt am Main </li>
              </ul>
              <img src={process.env.PUBLIC_URL + '/assets/Logo.png'} />
            </div>
          </Col>
          <Col span={24}>
            <div className="card-preview-bottom">
              <div>
                Die Mitgliedskarte ist nicht übertragbar. Für das Ausstellen
                einer neuen Karte (z.B. bei Verlust) fällt eine
                Bearbeitungsgebühr von 10 € an.
              </div>
              <div>
                EFSC-Satzung §7 (3): Die Mitgliedschaft beträgt mindestens 1
                Jahr. Der freiwillige Vereinsaustritt ist nur zum 30.6. oder
                31.12. eines jeden Jahres zulässig. Die Kündigung muss
                spätestens bis zum 31.5. bzw. 30.11. schriftlich erfolgen.
              </div>
            </div>
            <div className="card-bottom">
              <div>C4835633-00001</div>
            </div>
          </Col>
        </Row>
        <div className="validity">Gültigkeit 1.1.2021 - 31.12.2021</div>
      </Modal>
    </Fragment>
  );
};

export default CardPreview;
