export const importPageRoute = "/import";
export const unauthorizedRoute = "/unauthorized";
export const resetPasswordRoute = "/reset-password/:id";
export const creationMembershipCardRoute = "/card-creation";
export const batchPrintRoute = "/batch-print";
export const pushNotificationRoute = "/send-push-notifications";
export const membershipManagementRoute = "/membership-management";
export const reportsRoute = "/reports";
export const userGroupAdminRoute = "/user-group-admin";
export const settingsRoute = "/setting";
export const assignCardsRoute = "/assign-cards";
export const logsRoute = "/logs";
export const newLogsRoute = "/newLogs";

