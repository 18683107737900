import React, { useRef, useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Row,
    Col,
    Table,
    Checkbox,
    Typography,
    Button,
    Alert,
    message,
    Input,
    Space,
    notification,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import {
    userList,
    notificationPreference,
    sendPushNote,
    getUpdatedList,
} from "../../redux/import-export";
import { rfidList, updateRfid } from "../../functions/users";
import { TableRowSelection } from 'antd/lib/table/interface';

const { TextArea } = Input;
const { Title, Text } = Typography;
const CheckboxGroup = Checkbox.Group;

const SendPushNotifications = () => {
    const dispatch = useDispatch();
    const didMountNotificationPreferenceRef = useRef(false);
    const didMountGetUpdatedListRef = useRef(false);

    const [usersListData, setUsersListData] = useState([]);
    const [notificationData, setNotificationData] = useState([]);
    const [checkData, setCheckData] = useState([]);
    const [listNotes, setListNotes] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [counter, setCounter] = useState(0);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [paginationCount, setPaginationCount] = useState(1);
    const [rfid, setRfidList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [notificationHeader, setNotificationHeader] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");
    const [selectedNotification, setSelectedNotification] = useState([]); // Contains all selected Preferences in the form of an array
    const [searchObject, setSearchObject] = useState({});
    const [members, setMembers] = useState([]);
    const [memberCheck, setMemberCheck] = useState(false);

    const listNotificationPreferenceResponseData = useSelector(
        (state) => state.importExport.listNotificationPreferenceResponseData
    );
    const filteredNotificationResponse = useSelector(
        (state) => state.importExport.filteredNotificationResponse
    );

    useEffect(() => {
        if (didMountNotificationPreferenceRef.current) {
            if (listNotificationPreferenceResponseData) {
                if (listNotificationPreferenceResponseData.status === 200) {
                    const data = listNotificationPreferenceResponseData.data;
                    if (data) {
                        const notificationList = data;
                        let notificationAry = [];

                        for (let i = 0; i < notificationList.length - 1; i++) {
                            let newList = {};
                            newList.label = notificationList[i].label;
                            newList.value = notificationList[i]._id;
                            newList.isChecked = false;
                            notificationAry.push(newList);
                        }

                        let checkAllBox = notificationList.filter(
                            (item) => item.label === "Alle Benachrichtigungen"
                        );
                        setCheckData(checkAllBox[0]);
                        setNotificationData(notificationAry);
                    } else {
                        message.error(data.message);
                    }
                } else {
                    message.error(listNotificationPreferenceResponseData.message);
                }
            }
        } else {
            dispatch(notificationPreference(1));
            didMountNotificationPreferenceRef.current = true;
        }
    }, [listNotificationPreferenceResponseData]);

    useEffect(() => {
        if (didMountGetUpdatedListRef.current) {
            setLoading(false);

            if (filteredNotificationResponse) {
                if (filteredNotificationResponse.status === 200) {
                    const data = filteredNotificationResponse.data;
                    if (data) {
                        console.log("data success");
                        const pager = {...pagination };
                        let page = pager.current;
                        if (page === undefined) {
                            page = 1;
                        }
                        console.log("success");
                        const list = data;

                        let dataAry = [];

                        for (let i = 0; i < list.length; i++) {
                            let newList = {};
                            newList.username = `${
                list[i].firstname ? list[i].firstname : ""
              } ${list[i].surname ? list[i].surname : ""}`;
                            newList.key = list[i]["member_no"];
                            newList["Mitglied-Nr"] = setIds(list[i]["member_no"]);
                            let pushNoteArr = list[i].push_notification;

                            let notes = [];
                            for (let j = 0; j < pushNoteArr.length; j++) {
                                notes.push(pushNoteArr[j].label);
                            }
                            if (notes.length === 0) {
                                newList.consent = "Nein";
                            } else if (
                                notes.length >= 3 ||
                                notes.includes("Alle Benachrichtigungen")
                            ) {
                                newList.consent = "Alle";
                            } else if (notes.length <= 2) {
                                if (
                                    notes.includes(
                                        "Aktuelle Benachrichtigungen zum Vereinsbad Schwedlersee"
                                    ) &&
                                    notes.includes(
                                        "Aktuelle Benachrichtigungen zu Trainings- und Übungsbetrieb"
                                    )
                                ) {
                                    newList.consent = "Schwedler, Training";
                                } else if (
                                    notes.includes(
                                        "Aktuelle Benachrichtigungen zum Vereinsbad Schwedlersee"
                                    ) &&
                                    notes.includes("Allgemeine Benachrichtigungen des EFSC")
                                ) {
                                    newList.consent = "Schwedler, Allgemein";
                                } else if (
                                    notes.includes(
                                        "Aktuelle Benachrichtigungen zu Trainings- und Übungsbetrieb"
                                    ) &&
                                    notes.includes("Allgemeine Benachrichtigungen des EFSC")
                                ) {
                                    newList.consent = "Training, Allgemein";
                                } else if (
                                    notes.includes(
                                        "Aktuelle Benachrichtigungen zum Vereinsbad Schwedlersee"
                                    )
                                ) {
                                    newList.consent = "Schwedler";
                                } else if (
                                    notes.includes(
                                        "Aktuelle Benachrichtigungen zu Trainings- und Übungsbetrieb"
                                    )
                                ) {
                                    newList.consent = "Training";
                                } else if (
                                    notes.includes("Allgemeine Benachrichtigungen des EFSC")
                                ) {
                                    newList.consent = "Allgemein";
                                }
                            }
                            dataAry.push(newList);
                            const dataCount = dataAry.length;
                        }

                        setMembers(dataAry);

                        setUsersListData(dataAry);
                        const paginationData = {...pagination };
                        // Read total count from server

                        // const totalPage = Math.ceil(data.count / 100);
                        const totalPage = data.count;

                        setPaginationCount(totalPage);
                        // paginationData.total = Math.ceil(data.count / 100);
                        setPagination(paginationData);
                    } else {
                        message.error(data.message);
                    }
                } else {
                    message.error(filteredNotificationResponse.message);
                }
            }
        } else {
            dispatch(getUpdatedList({ preferenceId: [] }));
            didMountGetUpdatedListRef.current = true;
        }
    }, [filteredNotificationResponse]);

    const loadRFIDListData = () => {
        rfidList().then((res) => {
            setRfidList(res.data);
        });
    };

    const setIds = (id) => {
        let numString = String(id);
        let number = numString.length;
        if (number === 7) {
            return numString;
        }
        let less = 7 - number;
        for (let i = 0; i < less; i++) {
            numString = "0" + numString;
        }
        return numString;
    };

    const onSelectChange = (selectedRowKeys) => {
        // setSelectedRowKeys(selectedRowKeys);
        setMemberCheck(!memberCheck)
        setSelectedRowKeys(members.map((li) => {
            return parseInt(li['Mitglied-Nr'])
        }));
        if (memberCheck) {
            setSelectedRowKeys([]);
        }
    };


    //   const rowSelection = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    //   };


    const rowSelection: TableRowSelection < DataType > = {
        selectedRowKeys,
        onChange: onSelectChange
    };

    const onCheckAllChange = (e) => {
        let localNotificationData = notificationData;
        let localNotificationArray = [];
        let newPreferences = [];
        for (let i = 0; i < localNotificationData.length; i++) {
            let newPreference = {};
            localNotificationArray.push(localNotificationData[i].value);
            newPreference.notification_id = notificationData[i].value;
            newPreference.label = notificationData[i].label;
            newPreferences.push(newPreference);
        }

        let allChoice = {
            notification_id: checkData._id,
            label: checkData.label,
        };

        newPreferences.push(allChoice);

        let sendList = localNotificationArray;
        sendList.push(checkData._id);
        setListNotes(e.target.checked ? localNotificationArray : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);

        let preferencesObject = { preferenceId: sendList };
        if (e.target.checked) {
            dispatch(getUpdatedList(preferencesObject));
            setSelectedNotification(newPreferences);
        } else {
            dispatch(getUpdatedList({ preferenceId: [] }));
        }
    };

    const checkChanges = (list) => {
        setListNotes(list);
        setIndeterminate(!!list.length && list.length < notificationData.length);
        setCheckAll(list.length === notificationData.length);
        setSelectedNotification(list);

        let preferencesObject = { preferenceId: list };
        dispatch(getUpdatedList(preferencesObject));
    };

    const handleTableChange = (selectedPagination) => {
        const pager = {...pagination };
        pager.current = selectedPagination.current;
        const page = selectedPagination.current;
        setLoading(true);
        setPagination(pager);
        // dispatch(getUpdatedList(page));
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => ( <
            div style = {
                { padding: 8 }
            } >
            <
            Input placeholder = { `Suchen ${dataIndex}` }
            value = { selectedKeys[0] }
            onChange = {
                (e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter = {
                () => handleSearch(selectedKeys, confirm, dataIndex)
            }
            style = {
                { width: 188, marginBottom: 8, display: "block" }
            }
            /> <
            Space >
            <
            Button type = "primary"
            onClick = {
                () => handleSearch(selectedKeys, confirm, dataIndex)
            }
            icon = { < SearchOutlined / > }
            size = "small"
            style = {
                { width: 90 }
            } >
            Suchen <
            /Button> <
            Button onClick = {
                () => handleReset(clearFilters)
            }
            size = "small"
            style = {
                { width: 90 }
            } >
            Zurücksetzen <
            /Button> < /
            Space > <
            /div>
        ),
        filterIcon: (filtered) => ( <
            SearchOutlined style = {
                { color: filtered ? "#1890ff" : undefined }
            }
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] ?
            record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()) : "",
        render: (text) =>
            searchObject.searchedColumn === dataIndex ? ( <
                Highlighter highlightStyle = {
                    { backgroundColor: "#ffc069", padding: 0 }
                }
                searchWords = {
                    [searchObject.searchText]
                }
                autoEscape textToHighlight = { text ? text.toString() : "" }
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchObject({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    const onChangeMessage = (e) => {
        if (e.target.value.length <= 512) {
            setCounter(e.target.value.length);
            setNotificationMessage(e.target.value);
            if (e.target.value.length === 512) {
                setCounter(e.target.value.length);
                setError(true);
            } else {
                setError(false);
            }
        } else {
            setError(true);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchObject({ searchText: "" });
    };

    const userColumns = [{
            title: "Mitglied",
            dataIndex: "username",
            key: "username",
            sorter: (a, b) => a.username.length - b.username.length,
            sortDirections: ["descend", "ascend"],
            ...getColumnSearchProps("username"),
        },
        {
            title: "Mitgliedsnummer",
            dataIndex: "Mitglied-Nr",
            key: "Mitglied-Nr",
            sorter: (a, b) => a["Mitglied-Nr"] - b["Mitglied-Nr"],
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Zustimmung Push-Benachrichtigungen",
            dataIndex: "consent",
            key: "consent",
            sorter: (a, b) => a["consent"] - b["consent"],
            sortDirections: ["descend", "ascend"],
        },
    ];

    return ( <
            Fragment >
            <
            h2 className = "text-align-center" >
            <
            Text strong > Verschicken von Push Benachrichtigungen < /Text> < /
            h2 > <
            Title level = { 4 }
            className = "mt-0" >
            Titel der Nachricht <
            /Title> <
            Row >
            <
            Col md = { 10 } >
            <
            Input value = { notificationHeader }
            onChange = {
                (e) => {
                    setNotificationHeader(e.target.value);
                }
            }
            placeholder = "" /
            >
            <
            /Col> < /
            Row > <
            Title level = { 4 }
            className = "mt-0" >
            Inhalt der Nachricht <
            /Title> <
            Row >
            <
            Col md = { 10 } >
            <
            TextArea value = { notificationMessage }
            onChange = { onChangeMessage }
            placeholder = ""
            rows = { 5 }
            /> < /
            Col > <
            /Row> <
            Row > { counter }
            /512 {
            error ? ( <
                strong >
                &
                nbsp; Es können keine weiteren Zeichen mehr eingegeben werden <
                /strong>
            ) : (
                ""
            )
        } <
        /Row> <
    Title level = { 4 }
    className = "mt-32" >
        Auswahl Adressaten <
        /Title>

    <
    Row >
        <
        Col span = { 10 } >
        <
        Title level = { 5 }
    style = {
            { marginBottom: "4px" }
        } >
        Zustimmung Nachrichtenkategorie <
        /Title> <
    Title level = { 5 }
    className = "mt-0 mb-16" >
        Pflichtfeldauswahl <
        /Title> <
    div >
        <
        CheckboxGroup options = { notificationData }
    value = { listNotes }
    onChange = { checkChanges }
    /> <
    Checkbox indeterminate = { indeterminate }
    onChange = { onCheckAllChange }
    checked = { checkAll } > { checkData.label } <
        /Checkbox> < /
    div > {
            notificationHeader !== "" &&
            notificationMessage !== "" &&
            selectedRowKeys.length ? ( <
                Button type = "primary"
                className = "mt-32"
                danger onClick = {
                    () => {
                        if (notificationMessage !== "" && selectedRowKeys.length > 0) {
                            let sendData = {
                                title: notificationHeader,
                                body: notificationMessage,
                                member_no: selectedRowKeys,
                                notifications: selectedNotification,
                            };
                            dispatch(sendPushNote(sendData));
                            message.info("Benachrichtigung erfolgreich gesendet");
                            setSelectedRowKeys([]);
                            setNotificationMessage("");
                            setListNotes([]);
                            if (indeterminate) {
                                setIndeterminate(false);
                            } else {
                                setCheckAll(false);
                            }

                            let newArr = [...notificationData];
                            for (let i = 0; i < notificationData.length; i++) {
                                if (notificationData[i].isChecked === true) {
                                    notificationData[i].isChecked = false;
                                }
                            }
                            setNotificationData(newArr);
                            dispatch(getUpdatedList({ preferenceId: [] }));
                        } else if (
                            notificationMessage === "" ||
                            notificationHeader === ""
                        ) {
                            message.info(
                                "Sie können keine leere Push-Benachrichtigung senden"
                            );
                        } else if (selectedRowKeys.length === 0) {
                            message.info(
                                "Sie müssen mindestens einen Benutzer auswählen, an den die Benachrichtigung gesendet werden soll."
                            );
                        } else {
                            message.info(
                                "Ein Fehler ist aufgetreten. Bitte versuche es erneut."
                            );
                        }
                    }
                } >
                Versenden <
                /Button>
            ) : ( <
                Button type = "primary"
                className = "mt-32"
                danger disabled = { true } >
                Versenden <
                /Button>
            )
        } <
        /Col> <
    Col span = { 14 } >
        <
        Table rowSelection = { rowSelection }
    columns = { userColumns }
    dataSource = { usersListData }
    pagination = {
        {
            pageSize: 100,
            total: paginationCount,
            showSizeChanger: false,
        }
    }
    onChange = { handleTableChange }
    scroll = {
        { y: 240 }
    }
    /> < /
    Col > <
        /Row> < /
    Fragment >
);
};

export default SendPushNotifications;