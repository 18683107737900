import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { REMOVE_STORAGE } from '../redux/authentication/action-type';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import authenticationReducer from '../redux/authentication/reducer';
import importExportReducer from '../redux/import-export/reducer';

const persistConfig = {
  key: 'root',
  storage: storage, // here storage is localstorage
  whitelist: ['authentication'], // only items inside array will be persisted
  stateReconciler: autoMergeLevel2,
  debug: true
};

const importExportPersistConfig = {
  key: 'importExport',
  storage: storage,
  whitelist: ['currentSidebarItemKey']
};

const appReducer = combineReducers({
  authentication: authenticationReducer,
  importExport: persistReducer(importExportPersistConfig, importExportReducer)
});

const rootReducer = (state, action) => {
  if (action.type === REMOVE_STORAGE) {
    storage.removeItem('persist:root');
    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
