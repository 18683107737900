import React, { useRef, useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_VERSION } from "../../functions/users";
import { autheader } from "../../functions/users";

import {
  Row,
  Col,
  Select,
  DatePicker,
  Space,
  Typography,
  Radio,
  Spin,
  Button,
  Table,
  message,
  TimePicker,
  Input,
  Checkbox,
  Modal,
  Divider,
  notification,
} from "antd";
import moment from "moment";

import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;
const { Text, Title } = Typography;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const { RangePicker } = DatePicker;
const format = "HH:mm";

const AssignCards = () => {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const didMountUserListRef = useRef(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalAdminVisible, setIsModalAdminVisible] = useState(false);
  const [isSelectAllModalVisible, setIsSelectAllModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [multipleUpdateButton, setMultpleUpdateButton] = useState(true);

  const [cardsListData, setCardsListData] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);

  const [openingHours, setOpeningHours] = useState("");
  const [start, setStart] = useState({});
  const [startvalue, setStartvalue] = useState(null);
  const [endvalue, setEndvalue] = useState(null);
  const [end, setEnd] = useState({});

  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [group, setGroup] = useState("");
  const [valid, setValid] = useState(true);
  const [validity, setValidity] = useState(true);
  const [startDay, setStartDay] = useState(1);
  const [endDay, setEndDay] = useState(5);
  const [validFrom, setValidFrom] = useState("");
  const [validTill, setValidTill] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [checkAll, setCheckAll] = useState(false);

  const [paginationCount, setPaginationCount] = useState(1);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const checkName = (event) => {
    setName(event.target.value);
  };
  const checkGroup = (event) => {
    setGroup(event.target.value);
  };

  const checkStart = (value) => {
    setStartvalue(value);
    let hour = new Date(value._d).getHours();
    let minute = new Date(value._d).getMinutes();
    setStart({ hour, minute });
  };

  function minutesToHoursAndMinute(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    rhours = rhours < 10 ? `0${rhours}` : rhours;
    rminutes = rminutes < 10 ? `0${rminutes}` : rminutes;
    return rhours + ":" + rminutes;
  }

  const checkEnd = (value) => {
    setEndvalue(value);
    let hour = new Date(value._d).getHours();
    let minute = new Date(value._d).getMinutes();
    setEnd({ hour, minute });
  };
  const editHandler = async (e, y) => {
    setIsModalVisible(true);
    const { Authorization } = autheader();

    let response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/list-cards/${y.card_number}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
      }
    );
    let data = await response.json();

    if (data) {
      setValidity(y.isValid);
      setCardNumber(data.card_number);
      setGroup(data.group);
      setName(data.name);

      // setCardType(data.card_type);

      if (data.card_type === "admin") {
        setCardType("Admin");
      } else {
        // console.log(y);
        setCardType("Gast");

        let h1 = minutesToHoursAndMinute(data.hour.start_hour);
        let h2 = minutesToHoursAndMinute(data.hour.end_hour);
        setOpeningHours({
          start: h1,
          end: h2,
        });
        var weekday = new Array(7);
        weekday[0] = "Sonntag";
        weekday[1] = "Montag";
        weekday[2] = "Dienstag";
        weekday[3] = "Mittwoch";
        weekday[4] = "Donnerstag";
        weekday[5] = "Freitag";
        weekday[6] = "Samstag";
        setStartDay(weekday[data.valid_days_of_week.start]);
        setEndDay(weekday[data.valid_days_of_week.end]);
        let d = y.valid_from;
        setValidFrom(y.valid_from);
        setValidTill(y.valid_till);
      }
    }
    if (!data) {
      setCardNumber(y.card_number);

      setName("");
      setGroup("");
      setValidFrom("");
      setValidTill("");
    }
  };
  const showMultipleSelection = async (e, y) => {
    if (selectedRowKeys.length === 0) {
      setMultpleUpdateButton(false);
    } else {
      setMultpleUpdateButton(true);
    }
    for (let i = 0; i < selectedRowKeys.length; i++) {
      if (
        Number(selectedRowKeys[i]) >= Number("02801") &&
        Number(selectedRowKeys[i]) <= Number("02810")
      ) {
        setMultpleUpdateButton(false);
      }
    }
    setIsSelectAllModalVisible(true);
  };
  const editAdminModalHandler = async (e, y) => {
    setIsModalAdminVisible(true);
    const { Authorization } = autheader();

    let response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/list-cards/${y.card_number}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
      }
    );
    let data = await response.json();

    if (data) {
      setValidity(y.isValid);
      setCardNumber(data.card_number);
      setGroup(data.group);
      setName(data.name);

      setCardType(data.card_type);

      if (data.card_type === "admin") {
        setStartDay("");
        setEndDay("");
      } else {
        // console.log(y);
        setStartDay("Monday");
        setEndDay("Friday");
        let d = y.valid_from;
        setValidFrom(y.valid_from);
        setValidTill(y.valid_till);
      }
    }
    if (!data) {
      setValidity(y.isValid);
      setCardNumber(y.card_number);
      if (y.card_number >= "02801" && y.card_number <= "02810") {
        setCardType("admin");
      } else if (y.cardNumber >= "02811" && y.card_number <= "02900") {
        setCardType("guest");
      }
      setName("");
      setGroup("");
      setValidFrom("");
      setValidTill("");
    }
  };
  const tableColumns = [
    { title: "Kartennummer", dataIndex: "card_number", key: "card_number" },
    {
      title: "Karten-Typ",
      dataIndex: "card_type",
      render: (text, row, index) => {
        if (row.card_number >= "02801" && row.card_number <= "02810") {
          return "Admin";
        } else if (row.card_number >= "02811" && row.card_number <= "02900") {
          return "Gast";
        }
      },
    },

    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Gruppe", dataIndex: "group", key: "group" },
    {
      title: "Gültig Ab",
      dataIndex: "valid_from",
      render: (text, row, index) => {
        if (row.card_number >= "02801" && row.card_number <= "02810") {
          return "-";
        } else if (row.card_number >= "02811" && row.card_number <= "02900") {
          if (row.valid_from !== undefined) {
            return `${row.valid_from}`;
          } else if (row.valid_from === null) {
            return "";
          } else {
            return "";
          }
        }
      },
    },
    {
      title: "Gültig bis",
      dataIndex: "valid_till",
      render: (text, row, index) => {
        if (row.card_number >= "02801" && row.card_number <= "02810") {
          return "-";
        } else if (row.card_number >= "02811" && row.card_number <= "02900") {
          if (row.valid_till !== undefined) {
            return `${row.valid_till}`;
          } else {
            return "";
          }
        }
      },
    },
    {
      title: "Ist gültig",
      dataIndex: "isValid",
      render: (text, row, index) => {
        if (row.card_number >= "02801" && row.card_number <= "02810") {
          if (row.isValid === false) {
            return "Nicht";
          } else if (row.isValid === true) {
            return "Ja";
          } else if (!row.isValid) {
            return "";
          }
        } else if (row.card_number >= "02811" && row.card_number <= "02900") {
          if (row.isValid === false) {
            return "Nicht";
          } else if (row.isValid === true) {
            return "Ja";
          } else if (!row.isValid) {
            return "";
          }
        }
      },
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => {
        if (record.card_number >= "02811" && record.card_number <= "02900") {
          return <a onClick={(e) => editHandler(e, record)}>Bearbeiten</a>;
        } else if (
          record.card_number >= "02801" &&
          record.card_number <= "02810"
        ) {
          return (
            <a onClick={(e) => editAdminModalHandler(e, record)}>Bearbeiten</a>
          );
        }
      },
    },
  ];
  const handleAdminCancel = () => {
    setIsModalAdminVisible(false);
    setCheckAll(false);
    setIndeterminate(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setCheckAll(false);
    setIndeterminate(false);
  };
  const handleMultipleCancel = () => {
    setIsSelectAllModalVisible(false);
    setCheckAll(false);
    setIndeterminate(false);
  };
  const handleSelect = (value) => {
    setValid(value);
  };

  const handleTableChange = (selectedPagination) => {
    const pager = { ...pagination };
    pager.current = selectedPagination.current;
    const page = selectedPagination.current;
    setLoading(true);
    setPagination(pager);
    // dispatch(userList(page));
  };
  async function fetchCards() {
    const { Authorization } = autheader();

    let response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/list-cards`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
      }
    );
    let user = await response.json();

    for (let i = 0; i < user.length; i++) {
      user[i].key = user[i].card_number;
    }
    setCardsListData(user);
  }
  const updateMultipleCard = async (v) => {
    let startminute = start.hour * 60 + start.minute;
    let endminute = end.hour * 60 + end.minute;
    let data = {
      cardNumber: selectedRowKeys,
      valid_from: validFrom,
      group: group,
      valid_till: validTill,
      valid_days_of_week: {
        start: startDay,
        end: endDay,
      },
      hour: {
        start_hour: startminute,
        end_hour: endminute,
      },
      isValid: valid,
    };
    console.log(data);
    if (data.valid_from === "" || data.valid_till === "") {
      return alert("Bitte korrektes Datum eingeben");
    }
    try {
      const { Authorization } = autheader();

      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/multiple-card-update`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        setIsSelectAllModalVisible(false);
        setCheckAll(false);
        setIndeterminate(false);
        window.location.reload();
      }
    } catch (error) {
      throw error;
    }
  };
  const updateCard = async (v) => {
    let startminute = start.hour * 60 + start.minute;
    let endminute = end.hour * 60 + end.minute;
    let data = {
      card_number: cardNumber,
      group: group,
      name: name,
      valid_from: validFrom,
      valid_till: validTill,
      valid_days_of_week: {
        start: startDay,
        end: endDay,
      },
      hour: {
        start_hour: startminute,
        end_hour: endminute,
      },
      isValid: valid,
    };
    if (data.group === "" || data.name === "") {
      return alert("Bitte Wert eingeben");
    }
    try {
      const { Authorization } = autheader();

      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/edit_card_information`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        if (cardNumber >= "02801" && cardNumber <= "02810") {
          setIsModalAdminVisible(false);
        } else {
          setIsModalVisible(false);
        }
      }
    } catch (error) {
      throw error;
    }
  };
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  function countDown(message) {
    let secondsToGo = 1;
    const modal = Modal.success({
      title: message,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }
  const resetCards = async () => {
    try {
      const { Authorization } = autheader();

      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/reset-cards`,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        countDown("Kartendaten auf Standard zurückgesetzt");
      }
    } catch (error) {
      throw error;
    }
  };

  const showConfirm = async () => {
    confirm({
      title: "Möchten Sie die Karten zurücksetzen?",
      icon: <ExclamationCircleOutlined />,
      content: "Dies fordert dazu auf, alle Gästekarten zurückzusetzens",
      onOk() {
        resetCards();
      },
      onCancel() {},
    });
  };

  const handleStartDay = async (value) => {
    setStartDay(value);
  };
  const handleEndDay = async (value) => {
    setEndDay(value);
  };
  const onChangeDate = async (date, dateString) => {
    setValidFrom(dateString[0]);
    setValidTill(dateString[1]);
  };
  useEffect(() => {
    fetchCards();
  }, [cardsListData]);
  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <h2 className="text-align-center">
            <Text strong>Karten Zuordnen</Text>
          </h2>
        </Col>
      </Row>
      <Row className="mt-32 mb-16">
        <Col span={8}>
          <Button type="primary" onClick={showConfirm}>
            Zurücksetzen
          </Button>
        </Col>
        <Col span={3} offset={1}>
          <Button type="primary" onClick={(e) => showMultipleSelection(e)}>
            Ausgewählte bearbeiten
          </Button>
        </Col>
        <Col span={4}></Col>
      </Row>
      <Spin spinning={loading}>
        <Table
          rowSelection={rowSelection}
          columns={tableColumns}
          loading={loading}
          dataSource={cardsListData}

          // onChange={handleTableChange}
        />
      </Spin>
      <Modal
        title="Basic Modal"
        visible={isModalAdminVisible}
        onOk={updateCard}
        onCancel={handleAdminCancel}
        okText="Speichern"
        width={1000}
        destroyOnClose="true"
        cancelText="Abbrechen"
      >
        <Row className="mt-32 mb-16">
          <Col span={9}>
            <Text strong>Kartennummer: </Text>
            <Text>{cardNumber}</Text>
          </Col>
          <Col span={9} offset={1}>
            <Text strong>Karte-Typ: </Text>
            <Text>{cardType}</Text>
          </Col>
        </Row>
        <Divider />

        <Row className="mt-32 mb-16">
          <Col span={9}>
            <Text strong>Gruppe: </Text>
            <Input placeholder="Gruppe" value={group} onChange={checkGroup} />
          </Col>
          <Col span={9} offset={1}>
            <Text strong>Name: </Text>
            <Input placeholder="Name" value={name} onChange={checkName} />
          </Col>
        </Row>
        <Row className="mt-32 mb-16">
          <Col span={9}>
            <Text strong>Ist gültig: </Text>
            <Select
              defaultValue={validity}
              style={{ width: 120 }}
              onChange={handleSelect}
            >
              <Option value="true">Ja</Option>
              <Option value="false">Nicht</Option>
            </Select>
          </Col>
        </Row>
      </Modal>
      <Modal
        title="Fenster zum Bearbeiten der Daten von ausgewählten Karten"
        visible={isSelectAllModalVisible}
        onOk={updateMultipleCard}
        onCancel={handleMultipleCancel}
        okText="Speichern"
        width={1000}
        destroyOnClose="true"
        okButtonProps={
          multipleUpdateButton ? "" : { style: { display: "none" } }
        }
        cancelButtonProps={
          multipleUpdateButton ? "" : { style: { display: "none" } }
        }
        cancelText="Abbrechen"
      >
        <Row className="mt-32 mb-16">
          <Col span={9}>
            <Text strong>Gruppe: </Text>
            <Input placeholder="Gruppe" onChange={checkGroup} />
          </Col>
        </Row>
        <Divider />
        <Row className="mt-32 mb-16">
          <Col span={9}>
            <Text strong>Gültig ab-bis: </Text>
            <RangePicker onChange={onChangeDate} format={dateFormatList} />
          </Col>
          <Col span={9} offset={1}>
            <Text>{/* ab {validFrom} bis {validTill} */}</Text>
          </Col>
        </Row>
        <Divider />
        <Row className="mt-32 mb-16">
          <Col span={9}>
            <Text strong>Wochentag starten: </Text>
            <Select style={{ width: 120 }} onChange={handleStartDay}>
              <Option value="1">Montag</Option>
              <Option value="2">Dienstag</Option>
              <Option value="3">Mittwoch</Option>
              <Option value="4">Donnerstag</Option>
              <Option value="5">Freitag</Option>
              <Option value="6">Samstag</Option>
              <Option value="0">Sonntag</Option>
            </Select>
          </Col>
          <Col span={9} offset={1}>
            <Text strong>Wochentag endet: </Text>
            <Select style={{ width: 120 }} onChange={handleEndDay}>
              <Option value="1">Montag</Option>
              <Option value="2">Dienstag</Option>
              <Option value="3">Mittwoch</Option>
              <Option value="4">Donnerstag</Option>
              <Option value="5">Freitag</Option>
              <Option value="6">Samstag</Option>
              <Option value="0">Sonntag</Option>
            </Select>
          </Col>
        </Row>
        <Divider />
        <Row className="mt-32 mb-16">
          <Text strong>Öffnungszeit:</Text>
          &nbsp; Von &nbsp;
          <TimePicker format={format} onChange={checkStart} />
          &nbsp; Bis &nbsp;
          <TimePicker format={format} onChange={checkEnd} />
        </Row>

        <Divider />

        <Row className="mt-32 mb-16">
          <Col span={9}>
            <Text strong>Ist gültig: </Text>
            <Select style={{ width: 120 }} onChange={handleSelect}>
              <Option value="true">Ja</Option>
              <Option value="false">Nicht</Option>
            </Select>
          </Col>
        </Row>

        <Divider />
      </Modal>
      <Modal
        title="Fenster zum Bearbeiten von Kartendaten"
        visible={isModalVisible}
        onOk={updateCard}
        onCancel={handleCancel}
        okText="Speichern"
        width={1000}
        destroyOnClose="true"
        cancelText="Abbrechen"
      >
        <Row className="mt-32 mb-16">
          <Col span={9}>
            <Text strong>Kartennummer: </Text>
            <Text>{cardNumber}</Text>
          </Col>
          <Col span={9} offset={1}>
            <Text strong>Karten-Typ: </Text>
            <Text>{cardType}</Text>
          </Col>
        </Row>
        <Divider />

        <Row className="mt-32 mb-16">
          <Col span={9}>
            <Text strong>Gruppe: </Text>
            <Input placeholder="Gruppe" value={group} onChange={checkGroup} />
          </Col>
          <Col span={9} offset={1}>
            <Text strong>Name: </Text>
            <Input placeholder="Name" value={name} onChange={checkName} />
          </Col>
        </Row>
        <Divider />
        <Row className="mt-32 mb-16">
          <Col span={9}>
            <Text strong>Gültig ab-bis: </Text>
            <RangePicker onChange={onChangeDate} format={dateFormatList} />
          </Col>
          <Col span={9} offset={1}>
            <Text>
              ab {validFrom} bis {validTill}
            </Text>
          </Col>
        </Row>
        <Divider />
        <Row className="mt-32 mb-16">
          <Col span={9}>
            <Text strong>Wochentag starten: </Text>
            <Select
              value={startDay}
              style={{ width: 120 }}
              onChange={handleStartDay}
            >
              <Option value="1">Montag</Option>
              <Option value="2">Dienstag</Option>
              <Option value="3">Mittwoch</Option>
              <Option value="4">Donnerstag</Option>
              <Option value="5">Freitag</Option>
              <Option value="6">Samstag</Option>
              <Option value="0">Sonntag</Option>
            </Select>
          </Col>
          <Col span={9} offset={1}>
            <Text strong>Wochentag endet: </Text>
            <Select
              value={endDay}
              style={{ width: 120 }}
              onChange={handleEndDay}
            >
              <Option value="1">Montag</Option>
              <Option value="2">Dienstag</Option>
              <Option value="3">Mittwoch</Option>
              <Option value="4">Donnerstag</Option>
              <Option value="5">Freitag</Option>
              <Option value="6">Samstag</Option>
              <Option value="0">Sonntag</Option>
            </Select>
          </Col>
        </Row>
        <Divider />
        <Row className="mt-32 mb-16">
          <Text strong>Öffnungszeit:</Text>
          &nbsp; Von &nbsp;
          <TimePicker
            // defaultValue={moment(`${openingHours.start}`, format)}
            format={format}
            // value={startvalue}
            onChange={checkStart}
          />
          &nbsp; Bis &nbsp;
          <TimePicker
            // defaultValue={moment(`${openingHours.end}`, format)}
            format={format}
            // value={endvalue}
            onChange={checkEnd}
          />
          &nbsp;&nbsp; Von {openingHours.start} bis {openingHours.end}
        </Row>

        <Divider />

        <Row className="mt-32 mb-16">
          <Col span={9}>
            <Text strong>Ist gültig: </Text>
            <Select
              defaultValue={validity}
              style={{ width: 120 }}
              onChange={handleSelect}
            >
              <Option value="true">Ja</Option>
              <Option value="false">Nicht</Option>
            </Select>
          </Col>
        </Row>

        <Divider />
      </Modal>
    </Fragment>
  );
};

export default AssignCards;
