import React, { useEffect, useRef, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LockOutlined } from "@ant-design/icons";
import { Form, Input, Button, message, Row, Col } from "antd";
import { resetPasswordThunk } from "../../redux/authentication";
import { useHistory, useParams } from "react-router-dom";

const ResetPassword = () => {
  const dispatch = useDispatch();

  const didMountRef = useRef(false);

  const history = useHistory();

  let { id } = useParams();
  const resetToken = id;

  // ******** Redux State ********* //

  const resetPasswordAPIResponse = useSelector(
    (state) => state.authentication.resetPasswordAPIResponse
  );

  const onResetPasswordFinish = (values) => {
    const data = {
      password: values.new_pass_confirm,
    };
    dispatch(resetPasswordThunk(data, resetToken));
  };

  // ******* useEffects ******** //

  useEffect(() => {
    if (didMountRef.current) {
      if (resetPasswordAPIResponse) {
        if (resetPasswordAPIResponse.status === 200) {
          const resetAPIData = resetPasswordAPIResponse.data;
          if (resetAPIData.success) {
            message.success(resetAPIData.message);
            setTimeout(() => {
              history.push("/");
            }, 3000);
          } else {
            message.error(resetAPIData.message);
          }
        } else {
          message.error(resetPasswordAPIResponse.message);
        }
      }
    }

    didMountRef.current = true;
  }, [resetPasswordAPIResponse]);

  return (
    <Row justify="center" align="middle">
      <Col className="loginimg_text" span={6}>
        <img src={process.env.PUBLIC_URL + "/assets/Logo.png"} />
        <h1>SAM</h1>
        <h3>Steuerung App und Mitgliederausweise</h3>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={onResetPasswordFinish}
        >
          <Form.Item
            name="new_pass"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password
              maxLength="255"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="neues passwort"
              title="New Password"
            />
          </Form.Item>
          <Form.Item
            name="new_pass_confirm"
            dependencies={["new_pass"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("new_pass") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password
              maxLength="255"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="bestätige neues passwort"
            />
          </Form.Item>
          <Form.Item>
            <Row
              justify="center"
              align="middle"
              gutter={16}
              style={{ padding: "0 16px" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                zurücksetzen
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ResetPassword;
