import axios from "axios";
import { autheader } from "../../functions/users";
import { API_VERSION } from "../../functions/users";

import {
  uploadUsersFileResponse,
  exportFileResponse,
  fileUploadHistoryResponse,
  downloadPdfResponse,
  userListResponse,
  listNotificationPreferenceResponse,
  sendNotifications,
  filteredNotificationResponse,
} from "./action";

export function uploadUsersFile(file, onSuccess, onError) {
  return async function (dispatch) {
    try {
      const formdata = new FormData();
      formdata.append("uploadfile", file);
      const arg = {
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/upload-users`,
        data: formdata,
        headers: autheader(),
      };
      const response = await axios(arg);
      console.log("response", {response});

      if (response.status === 200) {
        onSuccess(response.data);
        dispatch(uploadUsersFileResponse(response));
      }
    } catch (err) {
      if (err.response === undefined) {
        const networkFailRes = {
          status: "failure",
          message: "Network error please try again",
        };
        onError(networkFailRes);
        dispatch(uploadUsersFileResponse(networkFailRes));
      }
       else if(err.response.data !== undefined){
        const failureRes = {
          message: err.response.data,
        };
        dispatch(uploadUsersFileResponse(failureRes));
      }
      else {
        const failureRes = {
          message: err.response,
        };
        onError(failureRes);
        dispatch(uploadUsersFileResponse(failureRes));
      }
    }
  };
}

export function exportFile() {
  return async function (dispatch) {
    try {
      const arg = {
        method: "GET",
        url: `${process.env.REACT_APP_SERVER_URI}/api/export`,

        // url: `${process.env.REACT_APP_LOCAL_URI}/api/export`
      };
      const response = await axios(arg);
      if (response.status === 200) {
        console.log("Export response", response);

        dispatch(exportFileResponse(response));
      }
    } catch (err) {
      if (err.response === undefined) {
        const networkFailRes = {
          status: "failure",
          message: "Network error please try again",
        };
        dispatch(exportFileResponse(networkFailRes));
      } else {
        const failureRes = {
          message: err.response,
        };
        dispatch(exportFileResponse(failureRes));
      }
    }
  };
}

export function getFileUploadHistory() {
  return async function (dispatch) {
    try {
      const arg = {
        method: "GET",
        url: `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/history`,
        headers: autheader(),
      };
      const response = await axios(arg);
      if (response.status === 200) {
        console.log("Upload response", response);

        dispatch(fileUploadHistoryResponse(response));
      }
    } catch (err) {
      if (err.response === undefined) {
        const networkFailRes = {
          status: "failure",
          message: "Network error please try again",
        };
        dispatch(fileUploadHistoryResponse(networkFailRes));
      } else {
        const failureRes = {
          message: err.response,
        };
        dispatch(fileUploadHistoryResponse(failureRes));
      }
    }
  };
}

export function downloadPdf(memberIds) {
  return async function (dispatch) {
    try {
      const arg = {
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/downloadPDf`,
        data: {
          memberIds: memberIds,
        },
        headers: autheader(),
      };
      const response = await axios(arg);
      console.log("Download PDF response", response);

      if (response.status === 200) {
        dispatch(downloadPdfResponse(response));
      }
    } catch (err) {
      if (err.response === undefined) {
        const networkFailRes = {
          status: "failure",
          message: "Network error please try again",
        };
        dispatch(downloadPdfResponse(networkFailRes));
      } else {
        const failureRes = {
          message: err.response,
        };
        dispatch(downloadPdfResponse(failureRes));
      }
    }
  };
}

export function sendPushNote(sendData) {
  return async function (dispatch) {
    try {
      const arg = {
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/send-push-notification`,
        data: sendData,
        headers: autheader(),
      };
      const response = await axios(arg);
      console.log("Push Notification Response", response);

      if (response.status === 200) {
        dispatch(sendNotifications(response));
      }
    } catch (err) {
      if (err.response === undefined) {
        const networkFailRes = {
          status: "failure",
          message: "Network error please try again",
        };
        dispatch(sendNotifications(networkFailRes));
      } else {
        const failureRes = {
          message: err.response,
        };
        dispatch(sendNotifications(failureRes));
      }
    }
  };
}

export function getUpdatedList(preferencesObject) {
  return async function (dispatch) {
    try {
      const arg = {
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/filter-user-list`,
        data: preferencesObject,
        headers: autheader(),
      };
      const response = await axios(arg);
      console.log("Updated List Response", response);

      if (response.status === 200) {
        dispatch(filteredNotificationResponse(response));
      }
    } catch (err) {
      if (err.response === undefined) {
        const networkFailRes = {
          status: "failure",
          message: "Network error please try again",
        };
        dispatch(filteredNotificationResponse(networkFailRes));
      } else {
        const failureRes = {
          message: err.response,
        };
        dispatch(filteredNotificationResponse(failureRes));
      }
    }
  };
}

export function userList(page) {
  return async function (dispatch) {
    try {
      const arg = {
        method: "GET",
        url: `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/user-list?page=${page}`,
        headers: autheader(),
      };
      const response = await axios(arg);
      if (response.status === 200) {
        console.log("User List response", response);

        dispatch(userListResponse(response));
      }
    } catch (err) {
      if (err.response === undefined) {
        const networkFailRes = {
          status: "failure",
          message: "Network error please try again",
        };
        dispatch(userListResponse(networkFailRes));
      } else {
        const failureRes = {
          message: err.response,
        };
        dispatch(userListResponse(failureRes));
      }
    }
  };
}

export function notificationPreference() {
  return async function (dispatch) {
    try {
      const arg = {
        method: "GET",
        url: `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/list-notification-preference`,
      };
      const response = await axios(arg);
      if (response.status === 200) {
        console.log("Success Notification Preference", response);

        dispatch(listNotificationPreferenceResponse(response));
      }
    } catch (err) {
      if (err.response === undefined) {
        const networkFailRes = {
          status: "failure",
          message: "Network error please try again",
        };
        dispatch(listNotificationPreferenceResponse(networkFailRes));
      } else {
        const failureRes = {
          message: err.response,
        };
        dispatch(listNotificationPreferenceResponse(failureRes));
      }
    }
  };
}
