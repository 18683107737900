import React, { useEffect, useRef, useState } from "react";
import { API_VERSION } from "../../functions/users";
import { autheader } from "../../functions/users";

import {
  Row,
  Col,
  Select,
  DatePicker,
  Space,
  Typography,
  Radio,
  Button,
  Table,
  Modal,
  Divider,
  Input,
  Checkbox,
  Image,
} from "antd";

import moment from "moment";

import { LineChartOutlined } from "@ant-design/icons";
import { Line, Column } from "@ant-design/charts";
import chart from "../../assets/charted.jpg";

const { RangePicker } = DatePicker;

const { Text, Title } = Typography;

const Reports = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startWeek, setStartWeek] = useState();
  const [endWeek, setEndWeek] = useState();
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [startYear, setStartYear] = useState();
  const [endYear, setEndYear] = useState();

  const [xField, setXField] = useState("");
  const [xFieldTitle, setXFieldTitle] = useState("Zeit");

  const [showTable, setShowTable] = useState(false);
  const [showLine, setShowLine] = useState(false);
  const [showBar, setShowBar] = useState(false);

  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  function countDown({ title, content }) {
    let secondsToGo = 1;
    const modal = Modal.error({
      title: title,
      content: content,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 2000);
  }

  const config = {
    data,
    height: 400,
    xField: xField,
    yField: "Mitglieder",
    xAxis: {
      title: {
        text: xFieldTitle,
        style: { fontSize: 16 },
      },
    },
    yAxis: {
      title: {
        text: "Eingecheckte Nutzer",
        style: { fontSize: 16 },
      },
    },
    point: {
      size: 7,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#2593fc",
        lineWidth: 3,
      },
    },
  };

  const barconfig = {
    data,
    xField: xField,
    yField: "Mitglieder",
    xAxis: {
      title: {
        text: xFieldTitle,
        style: { fontSize: 16 },
      },
    },
    yAxis: {
      title: {
        text: "Eingecheckte Nutzer",
        style: { fontSize: 16 },
      },
    },
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    meta: {
      Mitglieder: { alias: "Mitglieder" },
    },
  };
  const downloadImage = () => {
    ref.current?.downloadImage();
  };
  const onChangeWeek = async (date, dateString) => {
    setStartWeek(dateString[0]);
    setEndWeek(dateString[1]);
  };
  const onChangeMonth = async (date, dateString) => {
    setStartMonth(dateString[0]);
    setEndMonth(dateString[1]);
  };
  const onChangeYear = async (date, dateString) => {
    setStartYear(dateString[0]);
    setEndYear(dateString[1]);
  };

  const displayWeeklyChartFunction = async () => {
    let data = {
      start_week: startWeek,
      end_week: endWeek,
    };
    const { Authorization } = autheader();

    let response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/weekly-reports`,
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(data),
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      let res = await response.json();
      setXField("weekNumber");
      setXFieldTitle("Zeit in Wochen");
      setData(res);
      setShowTable(false);
      setShowBar(true);
      setShowLine(false);
      setDataSource([]);
    }
  };
  const displayMonthlyChartFunction = async () => {
    let data = {
      start_month: startMonth,
      end_month: endMonth,
    };
    const { Authorization } = autheader();

    let response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/monthly-reports`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      let res = await response.json();
      var month = [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ];
      for (let i = 0; i < res.length; i++) {
        let monthIndex = moment()
          .month(res[i].month - 1)
          .format("M");
        let monthName = month[monthIndex - 1];

        let year = res[i].date;
        year = year.toString();
        year = year.substring(0, 4);
        res[i].date = monthName + " " + year;
      }
      setXField("date");
      setXFieldTitle("Zeit in Monaten");
      setData(res);
      setShowBar(true);
      setShowLine(false);
      setShowTable(false);

      setDataSource([]);
    }
  };
  const displayYearlyChartFunction = async (e) => {
    console.log();
    let data = {
      start_year: startYear,
      end_year: endYear,
    };
    const { Authorization } = autheader();

    let response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/yearly-reports`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      let res = await response.json();
      setXField("date");
      setXFieldTitle("Zeit in Jahren");
      setData(res);
      setShowTable(false);
      setShowBar(true);
      setShowLine(false);
      setDataSource([]);
    }
  };

  const displayChartFunction = async () => {
    let start, end;
    if (!startDate) {
      return countDown({
        title: "Startdatum ist leer",
        content: `Bitte wählen Sie den Wert für das Startdatum`,
      });
    }

    if (startDate && !endDate) {
      start = moment(startDate).format("YYYY-M-D");
      let data = {
        date: start,
      };

      const { Authorization } = autheader();
      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/daily-reports`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        let res = await response.json();
        // setData(res);
        setShowTable(true);
        setShowBar(false);

        setShowLine(false);
        setDataSource(res);
        setData([]);

        // setXField("hour");
        // setXFieldTitle("Zeit in Stunden");
      }
    } else if (startDate && endDate) {
      if (startDate > endDate) {
        return countDown({
          title: "Datum ist ungültig",
          content: "Bitte Enddatum größer als Startdatum zuweisen start",
        });
      } else if (startDate < endDate) {
        start = moment(startDate).format("YYYY-M-D");
        end = moment(endDate).format("YYYY-M-D");
        let data = {
          start_date: start,
          end_date: end,
        };
        const { Authorization } = autheader();
        let response = await fetch(
          `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/reports`,
          {
            method: "POST",
            mode: "cors",
            headers: {
              Authorization,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        if (response.ok) {
          let res = await response.json();
          setShowTable(false);
          setXField("date");
          setShowBar(false);

          setShowLine(true);

          setXFieldTitle("Zeit in Tagen");
          setData(res);
          setDataSource([]);
        }
      }
    }
  };
  const columns = [
    {
      title: "Zeit in Stunden",
      dataIndex: "hour",
      key: "hour",
    },
    {
      title: "Eingecheckte Nutzer",
      dataIndex: "Mitglieder",
      key: "Mitglieder",
    },
  ];

  const ref = useRef();
  return (
    <div>
      <h2 className="text-align-center">
        <Text strong>Nutzerstatistiken</Text>
      </h2>
      <Row>
        <br />
      </Row>
      <Row className="mt-32">
        <Col span={6}>
          <Space direction="vertical">
            <Title level={4} className="mt-32">
              Täglich
            </Title>
            <Space direction="horizontal" className="mb-8">
              <DatePicker onChange={(val) => setStartDate(val)} />
              <DatePicker onChange={(val) => setEndDate(val)} />
            </Space>
            <Space direction="horizontal" className="mb-8">
              <Button
                type="primary"
                icon={<LineChartOutlined />}
                onClick={displayChartFunction}
              >
                Filtern
              </Button>
            </Space>
          </Space>
        </Col>
        <Col span={16}>
          <Row>
            <Col span={8}>
              <Space direction="vertical">
                <Title level={4} className="mt-32">
                  Wöchentlich
                </Title>
                <Space direction="horizontal" className="mb-8">
                  <RangePicker picker="week" onChange={onChangeWeek} />
                </Space>
                <Space direction="horizontal" className="mb-8">
                  <Button
                    type="primary"
                    icon={<LineChartOutlined />}
                    onClick={displayWeeklyChartFunction}
                  >
                    Filtern
                  </Button>
                </Space>
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical">
                <Title level={4} className="mt-32">
                  Monatlich
                </Title>
                <Space direction="horizontal" className="mb-8">
                  <RangePicker picker="month" onChange={onChangeMonth} />
                </Space>
                <Space direction="horizontal" className="mb-8">
                  <Button
                    type="primary"
                    icon={<LineChartOutlined />}
                    onClick={displayMonthlyChartFunction}
                  >
                    Filtern
                  </Button>
                </Space>
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical">
                <Title level={4} className="mt-32">
                  Jährlich
                </Title>
                <Space direction="horizontal" className="mb-8">
                  <RangePicker picker="year" onChange={onChangeYear} />
                </Space>
                <Space direction="horizontal" className="mb-8">
                  <Button
                    type="primary"
                    icon={<LineChartOutlined />}
                    onClick={displayYearlyChartFunction}
                  >
                    Filtern
                  </Button>
                </Space>
              </Space>
            </Col>
          </Row>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col span={14}>
          {showLine ? (
            <button
              type="button"
              onClick={downloadImage}
              style={{ marginRight: 24 }}
            >
              Bild herunterladen
            </button>
          ) : (
            ""
          )}
          {showBar ? (
            <Column
              {...barconfig}
              onReady={(plot) => {
                plot.on("plot:click", (evt) => {
                  const { x, y } = evt;
                  const { xField } = plot.options;
                  const tooltipData = plot.chart.getTooltipItems({ x, y });
                  console.log(tooltipData);
                });
              }}
            />
          ) : (
            ""
          )}
          {showLine ? <Divider /> : ""}
          {showLine ? <Line {...config} chartRef={ref} /> : ""}
          {showTable ? (
            <Table dataSource={dataSource} columns={columns} size="small" />
          ) : (
            ""
          )}
        </Col>
        <Col span={2}></Col>
        <Col span={8}></Col>
      </Row>
      <Row>{/* <Col span={12}></Col> */}</Row>
    </div>
  );
};

export default Reports;
