import React, { useRef, useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  Checkbox,
  Button,
  Space,
  message,
  Spin,
  Modal,
  Select,
  Typography,
  Input,
} from "antd";
import CardPreview from "./cardPreview";
import { SearchOutlined } from "@ant-design/icons";
import { downloadPdf, userList } from "../../redux/import-export";
import { jsPDF } from "jspdf";
import { isArray } from "util";
import {
  rfidList,
  updateRfid,
  userGroup,
  searchUser,
  autheader,
} from "../../functions/users";

const { Option } = Select;
const { Column } = Table;
const { Text } = Typography;

const CreationMembershipCard = () => {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const didMountUserListRef = useRef(false);

  // ******** Local State ********* //

  const [loading, setLoading] = useState(false);
  const [visibleCardPreviewModal, setVisibleCardPreviewModal] = useState(false);
  const [pdfUrls, setPDFUrls] = useState([]);
  const [checkedUsersData, setCheckedUsers] = useState([]);
  const [usersListData, setUsersListData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rfid, setRfidList] = useState([]);
  const [selectRfid, setSelectRfid] = useState("");
  const [sendRfid, setSendRfid] = useState("");
  const [selectEditValue, setSelectEditValue] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [paginationCount, setPaginationCount] = useState(1);
  const [selectedUserGroup, setSelectedUserGroup] = useState("");
  const [userGroupList, setUserGroupList] = useState([]);
  const [searchParam, setSearchParam] = useState("");

  // ******** Redux State ********* //

  const selectedUsersForPrint = useSelector(
    (state) => state.importExport.selectedUsersForPrint
  );

  const downloadPdfResponse = useSelector(
    (state) => state.importExport.downloadPdfResponse
  );

  const uploadUsersFileResponseState = useSelector(
    (state) => state.importExport.uploadUsersFileResponse
  );

  const userListData = useSelector((state) => state.importExport.userListData);

  // ******** Handlers ********* //

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onInputChange = (e) => {
    let inputString = e.target.value;
    setSearchParam(inputString);
  };

  const resetFunction = () => {
    setLoading(true);
    setSelectedRowKeys([]);
    dispatch(userList());
  };

  // const showCardPreview = () => {
  //   setVisibleCardPreviewModal(true);
  // };

  const handleDownloadPdf = (docURL) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);

      window.open(`${process.env.REACT_APP_SERVER_URI}/${docURL}`);
    }, 6000);
  };

  const searchQueryFunction = () => {
    setLoading(true);
    let startDate = null;
    let endDate = null;
    searchUser(searchParam, startDate, endDate).then((res) => {
      setLoading(false);
      let newData = res.data;

      if (newData) {
        const list = newData;

        let dataAry = [];

        for (let i = 0; i < list.length; i++) {
          let newList = {};
          newList.cardNumber = list[i].rfid?.card_number;
          newList.username = `${list[i].firstname ? list[i].firstname : ""} ${
            list[i].surname ? list[i].surname : ""
          }`;
          newList.user_group = list[i].user_group;
          newList.key = list[i]["member_no"];
          newList["Mitglied-Nr"] = setIds(list[i]["member_no"]);
          newList.date = list[i].entry_date;
          newList.geburtsdatum = list[i].dob;
          dataAry.push(newList);
        }

        setUsersListData(dataAry);
      } else {
        message.error(newData.message);
      }
    });
  };

  // ******** Use Effects ********* //

  useEffect(() => {
    if (didMountRef.current) {
      setLoading(false);
      if (downloadPdfResponse) {
        if (downloadPdfResponse.status === 200) {
          const data = downloadPdfResponse.data;
          if (data.success) {
            console.log("success");
            handleDownloadPdf(data.url);
          } else {
            message.error(data.message);
          }
        } else {
          message.error(downloadPdfResponse.message);
        }
      }
    } else {
      didMountRef.current = true;
    }
  }, [downloadPdfResponse]);

  useEffect(() => {
    if (didMountUserListRef.current) {
      setLoading(false);
      if (userListData) {
        if (userListData.status === 200) {
          const data = userListData.data.data;
          if (data) {
            const pager = { ...pagination };
            let page = pager.current;
            if (page === undefined) {
              page = 1;
            }
            console.log("success");
            const list = data;

            let dataAry = [];

            for (let i = 0; i < list.length; i++) {
              let newList = {};
              newList.cardNumber = list[i].rfid?.card_number;
              newList.username = `${
                list[i].firstname ? list[i].firstname : ""
              } ${list[i].surname ? list[i].surname : ""}`;
              newList.user_group = list[i].user_group;
              newList.key = list[i]["member_no"];
              newList["Mitglied-Nr"] = setIds(list[i]["member_no"]);
              newList.date = list[i].entry_date;
              newList.geburtsdatum = list[i].dob;
              dataAry.push(newList);
            }

            setUsersListData(dataAry);
            const paginationData = { ...pagination };
            // Read total count from server

            // const totalPage = Math.ceil(data.count / 100);
            const totalPage = userListData.data.totalRecords;

            setPaginationCount(totalPage);
            // paginationData.total = Math.ceil(data.count / 100);
            setPagination(paginationData);
          } else {
            message.error(data.message);
          }
        } else {
          message.error(userListData.message);
        }
      }
    } else {
      dispatch(userList(1));
      loadRFIDListData();
      createUserGroupList();
      didMountUserListRef.current = true;
    }
  }, [userListData]);

  const setIds = (id) => {
    let numString = String(id);
    let number = numString.length;
    if (number === 7) {
      return numString;
    }
    let less = 7 - number;
    for (let i = 0; i < less; i++) {
      numString = "0" + numString;
    }
    return numString;
  };

  const handlePrintPdf = async (event) => {
    // setLoading(true);
    console.log(selectedRowKeys);
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [10.8854, 6.8125],
    });
    var currentTimestamp = Date.now();
    for (let i = selectedRowKeys.length - 1; i >= 0; i--) {
      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/api/v1/getCardData/${selectedRowKeys[i]}`,
        { method: "GET", mode: "cors", headers: autheader() }
      );

      let data = await response.json();
      doc.setFont("Times", "Normal");
      doc.setFontSize(70).setFont(undefined, "bold");

      // doc.text("2022", 8.4, 1.4);
      doc.text("2023", 8.4, 1.4);
      doc.setFontSize(34);
      let mem = data.member_no.toString();
      mem = mem.padStart(7, "0");

      // doc
      //   .text(`${data.firstname} ${data.surname}`, 0.65, 5.82)
      //   .setFont(undefined, "bold");
      // doc.setFontSize(30);

      // doc.text(`${mem}`, 0.65, 6.29).setFont(undefined, "bold");

      doc.text(`${data.firstname} ${data.surname}`, 0.65, 6.18);
      doc.text(`${mem}`, 8.7, 6.18);

      if (i === 0) {
        console.log("here");
        break;
      }
      doc.addPage([10.8854, 6.8125], "landscape");
    }

    doc.save(`${currentTimestamp}.pdf`);
    doc.output(`dataurlnewwindow`);
    // dispatch(downloadPdf(selectedRowKeys));
  };

  const loadRFIDListData = () => {
    rfidList().then((res) => {
      let rfidArr = res.data;
      let newRfid = [];
      for (let i = 0; i < rfidArr.length; i++) {
        if (
          rfidArr[i].card_number < "02801" ||
          rfidArr[i].card_number > "02900"
        ) {
          newRfid.push(rfidArr[i]);
        }
      }
      setRfidList(newRfid);
    });
  };

  const createUserGroupList = () => {
    userGroup().then((res) => {
      setUserGroupList(res.data);
    });
  };

  const editHandler = (e, y) => {
    setIsModalVisible(true);
    setSelectedUserGroup(y.user_group);
    setSelectRfid(y.cardNumber);
    setSelectEditValue(y["Mitglied-Nr"]);

    let newUserList = userListData.data;

    let selectID = "";
    for (let i = 0; i < newUserList.length; i++) {
      if (setIds(newUserList[i]["member_no"]) === y["Mitglied-Nr"]) {
        selectID = newUserList[i].rfid?._id;
        break;
      }
    }

    setSendRfid(selectID);
  };

  const tableColumns = [
    { title: "Mitglied", dataIndex: "username", key: "username" },
    {
      title: "Mitgliedsnummer",
      dataIndex: "Mitglied-Nr",
      key: "Mitglied-Nr",
      sorter: (a, b) => a["Mitglied-Nr"] - b["Mitglied-Nr"],
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Eintrittsdatum",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Geburtsdatum",
      dataIndex: "geburtsdatum",
      key: "geburtsdatum",
    },
    { title: "Nutzergruppe", dataIndex: "user_group", key: "user_group" },
    { title: "Kartennummer", dataIndex: "cardNumber", key: "cardNumber" },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => (
        <a onClick={(e) => editHandler(e, record)}>Bearbeiten</a>
      ),
    },
  ];

  const handleTableChange = (selectedPagination) => {
    const pager = { ...pagination };
    pager.current = selectedPagination.current;
    const page = selectedPagination.current;
    setLoading(true);
    setPagination(pager);
    dispatch(userList(page));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    updateRfid(selectEditValue, sendRfid, selectedUserGroup).then((res) => {
      setIsModalVisible(false);
      dispatch(userList());
      loadRFIDListData();
    });
    // setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onRfidChange = (value) => {
    setSelectRfid(value);
    setSendRfid(value);
  };

  const onGroupChange = (value) => {
    setSelectedUserGroup(value);
  };
  const onBlur = () => {
    console.log("blur");
  };

  const onFocus = () => {
    console.log("focus");
  };

  return (
    <Fragment>
      <CardPreview
        visible={visibleCardPreviewModal}
        setVisibleCardPreviewModal={setVisibleCardPreviewModal}
      />
      <Row>
        <Col span={24}>
          <h2 className="text-align-center">
            <Text strong>Erstellung Mitgliedsausweise</Text>
          </h2>
        </Col>
      </Row>
      <Row className="mb-16">
        <Col span={8}>
          <Input placeholder="Suchen..." allowClear onChange={onInputChange} />
        </Col>
        <Col span={2} offset={1}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={searchQueryFunction}
          >
            Suchen
          </Button>
        </Col>
        <Col span={1} offset={1}>
          <Button type="primary" onClick={resetFunction}>
            Zurücksetzen
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Space>
            {/* EFSC 2021 : <Checkbox disabled />
            EFSC 2022 : <Checkbox disabled />
            EFSC 2023 : <Checkbox disabled /> */}
            EFSC 2024 : <Checkbox defaultChecked disabled />
          </Space>
        </Col>
        <Col span={24}>
          <Spin spinning={loading}>
            <Table
              rowSelection={rowSelection}
              columns={tableColumns}
              loading={loading}
              dataSource={usersListData}
              pagination={{
                pageSize: 100,
                total: paginationCount,
                showSizeChanger: false,
              }}
              onChange={handleTableChange}
              scroll={{ y: 240 }}
            />
          </Spin>
        </Col>
        <Row justify="end">
          <Col>
            <Space>
              <Button type="primary" onClick={handlePrintPdf}>
                Drucken
              </Button>
            </Space>
          </Col>
        </Row>
      </Row>
      <Modal
        title="Kartennummer und Benutzergruppe bearbeiten"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Speichern"
        cancelText="Abbrechen"
      >
        <Row>
          <Select
            value={selectRfid}
            // showSearch
            style={{ width: 200 }}
            placeholder="Wählen Sie Benutzer-RFID"
            optionFilterProp="children"
            onSelect={onRfidChange}
            onFocus={onFocus}
            onBlur={onBlur}
            // onSearch={onSearch}
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
          >
            {rfid.map((v) => (
              <Option key={v._id} value={v._id}>
                {v.card_number}
              </Option>
            ))}
          </Select>
        </Row>
        <br />
        <br />
        <Row>
          <Select
            value={selectedUserGroup}
            style={{ width: 200 }}
            placeholder="Wählen Sie Benutzergruppe"
            optionFilterProp="children"
            onSelect={onGroupChange}
            onFocus={onFocus}
            onBlur={onBlur}
          >
            {userGroupList.map((v) => (
              <Option key={v._id} value={v.group_name}>
                {v.group_name}
              </Option>
            ))}
          </Select>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default CreationMembershipCard;
