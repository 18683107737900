import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Layout,Row,Col } from "antd";
import { systemLogOutThunk } from '../../redux/authentication';
const { Header, Content } = Layout;

const HeaderContainer = () => {
  const loginAPIResponse = useSelector(
    state => state.authentication.loginAPIResponse
  );
  return (
    <Fragment>
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <Row>
          <Col span={24}>
          <div className="style-role-block">
        {loginAPIResponse &&
        'data' in loginAPIResponse &&
        'data' in loginAPIResponse.data &&
        'success' in loginAPIResponse.data &&
        loginAPIResponse.data.success === true &&
        'user' in loginAPIResponse.data.data ? (
          <div className="logo-color">
            <div>{loginAPIResponse.data.data.user.username}&nbsp;||&nbsp;</div>
            <div>Rolle - {loginAPIResponse.data.data.user.role}</div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
          </Col>
        </Row>
      </Header>
    </Fragment>
  );
};

export default HeaderContainer;
