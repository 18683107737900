import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Select,
  DatePicker,
  Space,
  Typography,
  Radio,
  Button,
  Table,
  Input,
  Checkbox,
  message
} from 'antd';
import { Modal } from 'antd';
import {DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {userGroup, addUserGroup, deleteGroup} from '../../functions/users';


const { Text, Title } = Typography;
const {RangePicker} = DatePicker;



const UserGroup = () => {
  const [newUserGroup, setNewUserGroup] = useState('');
  const [userGroupList, setUserGroupList] = useState([]);

  useEffect(() => {
    createUserGroupList();
  },[])

  const onSubmitClick = () => {
    if(newUserGroup===''){
     return Modal.error({
        title: 'Neue Nutzergruppe fehlt',
        content: 'Bitte geben Sie den Gruppennamen ein',
      });
    }

    let filterGroup = userGroupList.filter(grp => grp.group_name === newUserGroup);

    if(filterGroup.length > 0) {
      message.info('Benutzergruppe existiert bereits.');
    } else {
      addUserGroup(newUserGroup).then(res => {
        message.info('Benutzergruppe hinzugefügt.');
        setNewUserGroup('');
        createUserGroupList();
      });
    }
  }

  const onInputChange = (e) => {
    let inputString = e.target.value;
    setNewUserGroup(inputString);    
  }

  const createUserGroupList = () => {
    userGroup().then(res=>{
      setUserGroupList(res.data);
    })
  }

  const deleteChoice = (e) => {
    deleteGroup(e.target.value).then(res=>{
      createUserGroupList();
    }
    )
  }
  return (
    <div>
      <h2 className="text-align-center">
            <Text strong>Verwaltung Nutzergruppen</Text>
          </h2>
      <Row>
        <Col>
        <div>
        <Title level={4}>Bestehende Nutzergruppen</Title>
        <div className="make-scrollable">
        {userGroupList.map(v => (
            <Row  key={v._id} value={v.group_name} className="mb-8">
              <Col span={14}><Text>{v.group_name}</Text></Col>
              <Col span={6} offset={4}>
                <Button onClick={deleteChoice} value={v._id} className="no-pointer-events"><DeleteOutlined /></Button>
              </Col>
            </Row>
            ))
            }
        </div>
        </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Title level={4} className="mt-32">Neue Nutzergruppe</Title>
          <Space>
            <Text strong>
              Name
            </Text>
            <Input placeholder="EFSC Plus Karte" allowClear onChange={onInputChange}/>
          </Space>
        </Col>
        <Col span={16}>
          <Row className="mt-32">
            <Col span={8}>
              <Title level={5}>Zugangsberechtigung</Title>

              <Space direction="vertical">
              <Checkbox className="mb-10">Tor Oben</Checkbox>
              <Checkbox className="mb-10">Rotes Tor</Checkbox>
              <Checkbox className="mb-10">Drehkreuz</Checkbox>
              <Checkbox className="mb-10">Tor Oben</Checkbox>
              <Checkbox className="mb-10">Jugendraum</Checkbox>
              </Space>
            </Col>
            <Col span={16}>
              <Title level={5}>Uhrzeit</Title>

              <Space direction="vertical">
              <RangePicker showTime />
              <RangePicker showTime />
              <RangePicker showTime />
              <RangePicker showTime />
              <RangePicker showTime />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" className="mt-32">
        <Button danger type="primary" onClick={onSubmitClick}>Speichern</Button>
      </Row>
    </div>
  )
}

export default UserGroup;