import React, { Fragment, useEffect, useState } from 'react';
import { Modal, Image, Button, Row, Col, Typography, Space, Spin } from 'antd';
import bgimage from '../../../assets/pexels-matthias-cooper.jpg';

const { Text } = Typography;

const BatchPrintModal = props => {
  const [batches, setBatches] = useState(false);

  const {
    visible,
    setVisibleBatchPrintModal,
    selectedUsersForPrint,
    handlePrintPdf,
    loading
  } = props;

  useEffect(() => {
    if (selectedUsersForPrint) {
      let number = selectedUsersForPrint.length;
      let batches = new Array(Math.floor(number / 100))
        .fill(100)
        .concat(number % 100);
      setBatches(batches);
    }
  }, [selectedUsersForPrint]);

  const handlePrintBatch = (value, index) => {
    let start;
    let end;

    if (index === 0) {
      start = 1;
      end = value;
    } else {
      start = index * 100 + 1;
      if (value === 100) {
        end = (index + 1) * 100;
      } else {
        end = index * 100 + value;
      }
    }
    handlePrintPdf(start, end);
  };

  return (
    <Fragment>
      <Modal
        title="Batch-Druck"
        visible={visible}
        onCancel={() => setVisibleBatchPrintModal(false)}
        footer={
          <Button
            type="primary"
            onClick={() => {
              setVisibleBatchPrintModal(false);
            }}
          >
            schließen
          </Button>
        }
        width="80%"
        height="80%"
      >
        <Spin spinning={loading}>
          <Row>
            <Col span={24}>
              <div>
                {batches &&
                  batches.map((value, index) => {
                    return (
                      <div style={{ marginBottom: '20px' }}>
                        <Space>
                          Batch {index + 1} :
                          <Button
                            type="primary"
                            onClick={() => handlePrintBatch(value, index)}
                          >
                            Drucken
                          </Button>
                        </Space>
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </Spin>

        <br />
      </Modal>
    </Fragment>
  );
};

export default BatchPrintModal;
