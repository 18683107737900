import React, { useRef, useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Table, Checkbox, Button, Space, message, Spin, Modal, Select } from 'antd';
import CardPreview from '../cardPreview';
import { downloadPdf } from '../../../redux/import-export';
import { isArray } from 'util';
import BatchPrintModal from '../batchPrintModal';
import { rfidList, updatedUserDetail, updateRfid, userGroup } from '../../../functions/users';
import {
  setSelectedUsersForPrint
} from '../../../redux/import-export/action';
const { Column } = Table;
const { Option } = Select;

const BatchPrint = () => {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);

  // ******** Local State ********* //

  const [loading, setLoading] = useState(false);
  const [visibleCardPreviewModal, setVisibleCardPreviewModal] = useState(false);
  const [pdfUrls, setPDFUrls] = useState([]);
  const [checkedUsersData, setCheckedUsers] = useState([]);
  const [visibleBatchPrintModal, setVisibleBatchPrintModal] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [rfid, setRfidList] = useState([]);
  const [selectRfid, setSelectRfid] = useState('');
  const [sendRfid, setSendRfid] = useState('');
  const [userGroupList, setUserGroupList] = useState([]);
  const [selectEditValue, setSelectEditValue] = useState('');
  const [selectedUserGroup, setSelectedUserGroup] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  // ******** Redux State ********* //

  const selectedUsersForPrint = useSelector(
    state => state.importExport.selectedUsersForPrint
  );

  const downloadPdfResponse = useSelector(
    state => state.importExport.downloadPdfResponse
  );

  const uploadUsersFileResponseState = useSelector(
    state => state.importExport.uploadUsersFileResponse
  );

  // ******** Handlers ********* //

  const showCardPreview = () => {
    setVisibleCardPreviewModal(true);
  };

  const showBatchPrintModal = () => {
    setVisibleBatchPrintModal(true);
  };

  const handlePrintPdf = (start, end) => {
    let memberIds = [];
    if (
      selectedUsersForPrint &&
      isArray(selectedUsersForPrint) &&
      selectedUsersForPrint.length > 0
    ) {
      for (let i = start - 1; i < end; i++) {
        memberIds.push(selectedUsersForPrint[i].member_no);
      }
    }
    setLoading(true);

    dispatch(downloadPdf(memberIds));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    updateRfid(selectEditValue, sendRfid, selectedUserGroup).then(res => {
      setIsModalVisible(false);
      loadRFIDListData();

      updatedUserDetail(selectEditValue).then(res => {
        if(res.status === 200) {
          let newData = res.data;
          let localTableData = selectedUsersForPrint;
  
          for(let i = 0; i < localTableData.length; i++) {
            if(newData.user.member_no === localTableData[i].member_no) {
              let index = i;

              if (index !== -1) {
                localTableData[index].rfid = newData.rfid;
                localTableData[index].user_group = newData.user.user_group;
            } 
            }
          }
          dispatch(setSelectedUsersForPrint(localTableData));
          let list = selectedUsersForPrint;
          setTableData(dataRecreate(list));
          }
    })
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDownloadPdf = docURL => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);

      window.open(`${process.env.REACT_APP_SERVER_URI}/${docURL}`);
    }, 4000);
  };

  const loadRFIDListData = () => {
    rfidList().then(res => {
      setRfidList(res.data);
    });
  };

  const createUserGroupList = () => {
    userGroup().then(res=>{
      setUserGroupList(res.data);
    })
  }

  const onRfidChange = value => {
    setSendRfid(value);
    setSelectRfid(value);
  };

  const onGroupChange = value => {
    setSelectedUserGroup(value);
  }
  const onBlur = () => {
    console.log('blur');
  };

  const onFocus = () => {
    console.log('focus');
  };
  // ******** Use Effects ********* //

  useEffect(() => {
    if (didMountRef.current) {
      setLoading(false);
      if (downloadPdfResponse) {
        if (downloadPdfResponse.status === 200) {
          const data = downloadPdfResponse.data;
          if (data.success) {
            handleDownloadPdf(data.url);
          } else {
            message.error(data.message);
          }
        } else {
          message.error(downloadPdfResponse.message);
        }
      }
    } else {
      didMountRef.current = true;
    }
  }, [downloadPdfResponse]);

  const dataRecreate = (list) => {
    let dataAry = [];
      for (let i = 0; i < list.length; i++) {
        let newList = {};
        newList.cardNumber = list[i].rfid?.card_number;
        newList.username = `${list[i].firstname ? list[i].firstname : ''} ${list[i].surname ? list[i].surname : ''}`;
        newList.key = list[i]['member_no'];
        newList.group = list[i]['user_group'];
        newList['Mitglied-Nr'] = list[i]['member_no'];
        dataAry.push(newList);
        }
    return dataAry;
  }

  useEffect(() => {
    if(selectedUsersForPrint) {
      const list = selectedUsersForPrint;

      loadRFIDListData();
      createUserGroupList();
      
      setTableData(dataRecreate(list));
    }
  }, [])

  const editHandler = (e, y) => {
    setIsModalVisible(true);
    setSelectRfid(y.cardNumber);
    setSelectedUserGroup(y.group);
    setSelectEditValue(y['Mitglied-Nr']);

    let newUserList = selectedUsersForPrint;
    
    let selectID = '';
    for(let i = 0; i < newUserList.length; i++) {
      if(newUserList[i]['member_no'] === y['Mitglied-Nr']) {
        selectID = newUserList[i].rfid._id;
        break;
      } 
    }

    setSendRfid(selectID);
  };

  let tableColumns = [
    {
      title: 'Mitglied',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Mitgliedsnummer',
      dataIndex: 'Mitglied-Nr',
      key: 'Mitglied-Nr'
    },
    {
      title: 'Kartennummer',
      dataIndex: 'cardNumber',
      key: 'cardNumber'
    },
    {
      title: 'Nutzergruppe',
      dataIndex: 'group',
      key: 'group'
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (text, record) => (
        <a onClick={e => editHandler(e, record)}>Bearbeiten</a>
      )
    }
  ];

  return (
    <Fragment>
      <CardPreview
        visible={visibleCardPreviewModal}
        setVisibleCardPreviewModal={setVisibleCardPreviewModal}
      />

      <BatchPrintModal
        visible={visibleBatchPrintModal}
        setVisibleBatchPrintModal={setVisibleBatchPrintModal}
        selectedUsersForPrint={selectedUsersForPrint}
        handlePrintPdf={handlePrintPdf}
        loading={loading}
      />

      <Row>
        <Col span={24}>
          <h2>Erstellung Mitgliedsausweise</h2>
        </Col>
        <Col span={24}>
          <Space>
            EFSC 2021 : <Checkbox defaultChecked disabled />
            EFSC 2022 : <Checkbox disabled />
          </Space>
        </Col>
        <Col span={24}>
          <Table
            size="small"
            dataSource={tableData}
            // onChange={handleTableChange}
            columns={tableColumns}
            pagination={false}
            scroll={{ y: 300 }}
          />
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Col>
              <Space>
                <Button type="primary" onClick={showCardPreview}>
                  Vorschau
                </Button>
                <Button type="primary" onClick={showBatchPrintModal}>
                  Drucken
                </Button>
                {/* <Button type="primary" onClick={handlePrintPdf}>
                  Drucken
                </Button> */}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Space>
            Anzahl zu druckende Ausweise{' '}
            {selectedUsersForPrint &&
              isArray(selectedUsersForPrint) &&
              selectedUsersForPrint.length}
          </Space>
        </Col>
      </Row>
      <Modal
        title="Kartennummer und Benutzergruppe bearbeiten"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Speichern"
        cancelText="Abbrechen"
      >
        <Row>
          <Select
            // showSearch
            value={selectRfid}
            style={{ width: 200 }}
            placeholder="Wählen Sie Benutzer-RFID"
            optionFilterProp="children"
            onChange={onRfidChange}
            onFocus={onFocus}
            onBlur={onBlur}
            // onSearch={onSearch}
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
          >
            {rfid.map(v => (
              <Option key={v._id} value={v._id}>
                {v.card_number}
              </Option>
            ))}
          </Select>
        </Row>
        <br />
        <br />
        <Row>
          <Select
            value={selectedUserGroup}
            style={{ width: 200 }}
            placeholder="Wählen Sie Benutzergruppe"
            optionFilterProp="children"
            onChange={onGroupChange}
            onFocus={onFocus}
            onBlur={onBlur}
            
          >
            {userGroupList.map(v => (
              <Option key={v._id} value={v.group_name}>
                {v.group_name}
              </Option>
            ))
            }
          </Select>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default BatchPrint;
