import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import routes from './routeData';
import PublicRoute from './public';
import PrivateRoute from './private';
import {createBrowserHistory} from 'history';

const history = createBrowserHistory();

const Routes = () => (
    <Router history={history}>
      <Switch>
        {routes.map((route, i) => {
          if (route.authorization) {
            return <PrivateRoute key={i} {...route} />;
          } else {
            return <PublicRoute key={i} {...route} />;
          }
        })}
      </Switch>
    </Router>
  );
  
  export default Routes;

