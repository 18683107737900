import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result, Button } from 'antd';
import { Layout } from 'antd';

const Unauthorized = () => {
  const history = useHistory();
  return (
    <Layout>
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this system."
        extra={
          <Button type="primary" onClick={() => history.push('/')}>
            Back To Login Page
          </Button>
        }
      />
    </Layout>
  );
};
export default Unauthorized;
