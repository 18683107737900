import * as types from './action-type';

const initialState = {
  loginAPIResponse: null,
  logoutAPIResponse: null,
  forgetPasswordAPIResponse: null,
  resetPasswordAPIResponse: null
};

const Authentication = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.LOGIN_API_RESPONSE:
      return {
        ...state,
        loginAPIResponse: payload
      };
    case types.LOGOUT_API_RESPONSE:
      return {
        ...state,
        logoutAPIResponse: payload
      };
    case types.FORGET_PASSWORD_API_RESPONSE:
      return {
        ...state,
        forgetPasswordAPIResponse: payload
      };
    case types.RESET_PASSWORD_API_RESPONSE:
      return {
        ...state,
        resetPasswordAPIResponse: payload
      };

    default:
      return state;
  }
};

export default Authentication;
