import React from 'react';
import {Provider} from 'react-redux';
import {store, persistor} from './store';
import {PersistGate} from 'redux-persist/integration/react';
import Routes from './routes';
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './style/main.less'

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <div className="App">
        <Routes />
      </div>
    </PersistGate>
  </Provider>
);

export default App;
