import React from 'react';
import FooterComponent from '../components/footer';
import SideBarMenu from '../containers/sidebar';
import { Layout } from 'antd';
import HeaderContainer from '../sharedComponents/HeaderContainer';
import './siteStructure.css';

const { Content } = Layout;

const SiteStructure = props => {
  const { children } = props;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SideBarMenu />
      <Layout className="site-layout">
        <HeaderContainer />
        <Content
          className="site-layout"
          style={{ padding: '0 20px', marginTop: 44 }}
        >
          <div
            className="site-layout-background"
            style={{ padding: 20, minHeight: 640 }}
          >
            {children}
          </div>
        </Content>
        <FooterComponent />
      </Layout>
    </Layout>
  );
};

export default SiteStructure;
