import axios from "axios";

export const API_VERSION = "api/v1";

export const userList = async () =>
  await axios.get(
    `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/user-list`,
    {
      headers: autheader(),
    }
  );

export const rfidList = async () =>
  await axios.get(`${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/rfids`, {
    headers: autheader(),
  });

export const userGroup = async () =>
  await axios.get(
    `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/user-group-list`,
    {
      headers: autheader(),
    }
  );

export const updatedUserDetail = async (memno) => {
  return await axios.get(
    `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/user-detail?member_no=${memno}`
  );
};

export const searchUser = async (param, startDate, endDate) =>
  await axios.get(
    `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/list-search?search=${param}&startdate=${startDate}&enddate=${endDate}`,
    {
      headers: autheader(),
    }
  );

export const deleteGroup = async (param) => {
  await axios.delete(
    `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/remove-user-group`,
    {
      data: {
        groupId: param,
      },
      headers: autheader(),
    }
  );
};

export const updateRfid = async (mno, rfid, u_group) => {
  await axios.post(
    `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/update-user-rfid`,
    {
      member_no: mno,
      rfid: rfid,
      group_name: u_group,
    },
    {
      headers: autheader(),
    }
  );
};

export const updatePreferences = async (mems, notes) => {
  await axios.post(
    `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/update-user-notification`,
    {
      member_no: mems,
      notifications: notes,
    },
    {
      headers: autheader(),
    }
  );
};

export const addUserGroup = async (group) => {
  await axios.post(
    `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/add-user-group`,
    {
      group_name: group,
    },
    {
      headers: autheader(),
    }
  );
};

export const autheader = () => {
  let storageItem = JSON.parse(localStorage.getItem("persist:root"));
  let authObj = JSON.parse(storageItem.authentication);

  let token = authObj.loginAPIResponse.data.data.token;
  return { Authorization: `Bearer ${token}` };
};
