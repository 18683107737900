import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import Layout from './siteStructure';

import { Result, Button } from 'antd';

const PrivateRoute = ({
  component: Component,
  websiteLayout,
  permission,
  ...rest
}) => {
  const history = useHistory();

  const loginAPIResponse = useSelector(
    state => state.authentication.loginAPIResponse
  );

  return (
    <Route
      {...rest}
      render={props =>
        loginAPIResponse &&
        'data' in loginAPIResponse &&
        'success' in loginAPIResponse.data &&
        loginAPIResponse.data.success === true ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : loginAPIResponse === null ||
          (loginAPIResponse &&
            'data' in loginAPIResponse &&
            'success' in loginAPIResponse.data &&
            loginAPIResponse.data.success === false) ? (
          // not logged in so redirect to login page with the return url

          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        ) : (
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Button type="primary" onClick={() => history.push('/')}>
                Back Home
              </Button>
            }
          />
        )
      }
    />
  );
};

export default PrivateRoute;
