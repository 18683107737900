import React, { useState, useEffect, Fragment } from "react";
import { autheader } from "../../functions/users";

import { API_VERSION } from "../../functions/users";

import {
  Row,
  Col,
  DatePicker,
  Typography,
  Card,
  Spin,
  Button,
  Table,
  Statistic,
  Divider,
} from "antd";

import moment from "moment";
import {
  SearchOutlined,
  CheckOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { Tabs } from 'antd';
import NewLogs from "../newLogs";

const { Text } = Typography;

const Logs = () => {
  const [loading, setLoading] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const [dateValue, setDateValue] = useState();
  const [statistics, setStatistics] = useState({
    counter: "",
    maxCapacity: "",
    buffer: "",
    bufferCounter: "",
  });

  const [currentTab, setCurrentTab] = useState(1)

  const dateFormat = "MM/DD/YYYY";

  const searchQueryFunction = () => {
    setLoading(true);
    let date = null;
    if (dateValue !== null) {
      date = moment(dateValue).format("YYYY-M-D");
    }
    const { Authorization } = autheader();

    fetch(
      `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/daily-logs?date=${date}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        let newData = data;
        setLoading(false);
        if (newData) {
          for (let i = 0; i < newData.length; i++) {
            newData[i].key = newData[i]._id;
          }
          setLogsData(newData);
        }
      });
  };

  const tableColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Mitgliedsnummer", dataIndex: "memberNo", key: "memberNo" },
    {
      title: "Check-In-Zeit",
      dataIndex: "checkInTime",
      render: (text, row, index) => {
        if (row.checkInTime) {
          // let time = new Date(row.checkInTime).toTimeString();
          let time = new Date(row.checkInTime).toLocaleTimeString();

          // time = time.replace("GMT+0200 (Central European Summer Time)", "");
          // time = time.replace("GMT+0200 (Mitteleuropäische Sommerzeit)", "");
          return time;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Check-In Using",
      dataIndex: "checkInUsing",
      render: (text, row, index) => {
        if (row.checkInUsing === "RFID") {
          return "Karte";
        } else return row.checkInUsing;
      },
    },
    {
      title: "Check-Out Using",
      dataIndex: "checkOutUsing",
      render: (text, row, index) => {
        if (row.checkOutUsing === "RFID") {
          return "Karte";
        } else return row.checkOutUsing;
      },
    },
    {
      title: "Check-Out-Zeit",
      dataIndex: "checkOutTime",
      render: (text, row, index) => {
        if (row.checkOutTime) {
          // console.log(new Date(row.checkOutTime).toLocaleTimeString());
          let time = new Date(row.checkOutTime).toLocaleTimeString();
          // time = time.replace("GMT+0200 (Central European Summer Time)", "");
          // console.log(time);
          // time = time.replace("GMT+0200 (Mitteleuropäische Sommerzeit)", "");

          return time;
        } else return "-";
      },
    },
    {
      title: "Datum",
      dataIndex: "date",
      // key: "date",
      render: (text, row, index) => {
        if (row.date) {
          // console.log(row.date);
          let localDate = new Date(row.date).toLocaleDateString();
          return localDate;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {},
  ];

  async function fetchLogs() {
    const { Authorization } = autheader();

    let response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/daily-logs`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
      }
    );
    let logs = await response.json();

    for (let i = 0; i < logs.length; i++) {
      logs[i].key = logs[i]._id;
    }
    setLogsData(logs);
  }

  async function fetchCurrentStatistics() {
    const { Authorization } = autheader();

    let response = await fetch(
      `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/current-statistics`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization,
          "Content-Type": "application/json",
        },
      }
    );
    let stat = await response.json();
    setStatistics({
      counter: stat.counter.counter,
      maxCapacity: stat.capacity.maxCapacity,
      buffer: stat.capacity.buffer,
      bufferCounter: stat.counter.bufferCounter,
    });
  }

  useEffect(() => {
    fetchCurrentStatistics();
  }, []);

  useEffect(() => {
    fetchLogs();
  }, []);

  const __renderDailyLogs = () => {
    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={6}>
            <Card>
              <Statistic
                title="Checked-In"
                value={statistics.counter}
                suffix={"/ " + statistics.maxCapacity}
                prefix={<CheckOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Buffer"
                value={statistics.bufferCounter}
                suffix={"/ " + statistics.buffer}
                prefix={<LoadingOutlined />}
              />
            </Card>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={6}>
            <DatePicker
              format={dateFormat}
              onChange={(val) => setDateValue(val)}
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={6}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={searchQueryFunction}
            >
              Suchen
            </Button>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Table
            //   rowSelection={rowSelection}
            columns={tableColumns}
            loading={loading}
            dataSource={logsData}
          // onChange={handleTableChange}
          />
        </Spin>
      </React.Fragment>
    )
  }

  const __renderNewLogs = () => {
    return <NewLogs />
  }

  const __changeTabs = (tab) => {
    setCurrentTab(tab)
  }

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <h2 className="text-align-center">
            <Text strong>Täglicher Bericht</Text>
          </h2>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Tabs
            defaultActiveKey={currentTab}
            items={[
              {
                label: 'Daily Log',
                key: '1',
                children: __renderDailyLogs(),
              },
              {
                label: 'New Log',
                key: '2',
                children: __renderNewLogs()
              },
            ]}
            onChange={__changeTabs}
          />
        </Col>
      </Row>
      {/* <Divider />
        <Row>
          <Col span={24}>
            <h2 className="text-align-center">
              <Text strong>Täglicher Bericht</Text>
            </h2>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Card>
              <Statistic
                title="Checked-In"
                value={statistics.counter}
                suffix={"/ " + statistics.maxCapacity}
                prefix={<CheckOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Buffer"
                value={statistics.bufferCounter}
                suffix={"/ " + statistics.buffer}
                prefix={<LoadingOutlined />}
              />
            </Card>
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={18}>
            <DatePicker
              format={dateFormat}
              onChange={(val) => setDateValue(val)}
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={6}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={searchQueryFunction}
            >
              Suchen
            </Button>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Table
            //   rowSelection={rowSelection}
            columns={tableColumns}
            loading={loading}
            dataSource={logsData}
          // onChange={handleTableChange}
          />
        </Spin> */}
    </Fragment>
  );
};

export default Logs;
