import React, { useEffect, useRef, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, message, Modal, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {
  systemLoginThunk,
  forgetPasswordThunk
} from '../../redux/authentication';
import { unauthorizedRoute, importPageRoute } from '../../routes/routeRules';
import { setSideBarItemKey } from '../../redux/import-export/action';

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }) => {
  const prevVisibleRef = useRef();
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;
  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
  }, [visible]);
};

const LoginForm = () => {
  const dispatch = useDispatch();
  const didMountRef = useRef(false);
  const didMountForgetPassRef = useRef(false);

  const history = useHistory();

  // ******** Local State ********* //

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    visible
  });

  // ******** Redux State ********* //

  const loginAPIResponse = useSelector(
    state => state.authentication.loginAPIResponse
  );

  const forgetPasswordAPIResponse = useSelector(
    state => state.authentication.forgetPasswordAPIResponse
  );

  // ******* Handlers ******* //

  const onFinish = values => {
    dispatch(systemLoginThunk(values));
  };

  const redirectUsers = userData => {
    const userRoles = userData ? userData.user.role : '';

    if (userRoles === 'Administrator') {
      history.push(importPageRoute);
    } else {
      history.push(unauthorizedRoute);
    }
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    form.submit();
    setLoading(true); // forget password modal ok button loader
    const data = {
      email: email
    };

    dispatch(forgetPasswordThunk(data));
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSetEmail = e => {
    const value = e.target.value;
    setEmail(value);
  };

  // ******* useEffects ******** //

  useEffect(() => {
    // if (didMountRef.current) {
    if (loginAPIResponse) {
      if (loginAPIResponse.status === 200) {
        const loginAPIData = loginAPIResponse.data;

        if (loginAPIData.success) {

          dispatch(setSideBarItemKey('1'));
          redirectUsers(loginAPIData.data);
        } else {
          message.error(loginAPIData.message);
        }
      } else {
         alert("E-Mail und Passwort stimmen nicht überein. Bitte versuchen Sie es erneut.")
      }
    }
    // }

    // didMountRef.current = true;
  }, [loginAPIResponse]);

  useEffect(() => {
    if (didMountForgetPassRef.current) {
      setLoading(false);
      if (forgetPasswordAPIResponse) {
        if (forgetPasswordAPIResponse.status === 200) {
          const forgetPassAPIData = forgetPasswordAPIResponse.data;
          if (forgetPassAPIData.success) {
            message.success(forgetPassAPIData.message);
            setTimeout(() => {
              setVisible(false);
              setEmail('');
            }, 2000);
          } else {
            message.error(forgetPassAPIData.message);
          }
        } else {
          message.error(forgetPasswordAPIResponse.message);
        }
      }
    }
    didMountForgetPassRef.current = true;
  }, [forgetPasswordAPIResponse]);

  return (
    <Fragment>
      <Modal
        title="Bitte gib Deine E Mail Adresse ein, um das Passwort zurückzusetzen"
        visible={visible}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        footer={[
          <Button key="Cancel" onClick={handleCancel}>
            stornieren
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            einreichen
          </Button>
        ]}
      >
        <Form name="normal_login" form={form} className="login-form">
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie Ihre E-Mail-Adresse ein'
              }
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="email"
              onChange={handleSetEmail}
              value={email}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Row justify="center" align="middle">
        <Col className="loginimg_text" span={6}>
          <img src={process.env.PUBLIC_URL + '/assets/Logo.png'} />
          <h1>SAM</h1>
          <h3>Steuerung App und Mitgliederausweise</h3>
          <Form name="normal_login" className="login-form" onFinish={onFinish}>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message:
                    'Bitte geben Sie Ihren Benutzernamen oder Ihre E-Mail-Adresse ein'
                }
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Nutzer"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie Ihr Passwort ein'
                }
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="Password"
                placeholder="Passwort"
              />
            </Form.Item>
            <Form.Item>
              <Button className="login-form-forgot" onClick={showModal}>
                Passwort vergessen
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Anmeldung
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

export default LoginForm;
