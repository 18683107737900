import React, { useRef, useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_VERSION } from "../../functions/users";
import { autheader } from "../../functions/users";

import {
  Row,
  Col,
  Select,
  DatePicker,
  Space,
  Typography,
  Radio,
  Spin,
  Button,
  Table,
  message,
  Input,
  Checkbox,
  Switch,
  Modal,
  Divider,
  notification,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

import {
  downloadPdf,
  userList,
  notificationPreference,
} from "../../redux/import-export";
import {
  searchUser,
  userGroup,
  updateRfid,
  updatePreferences,
} from "../../functions/users";

const { Text, Title } = Typography;

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

const MembershipManagement = () => {
  const dispatch = useDispatch();
  const didMountUserListRef = useRef(false);
  const didMountNotificationPreferenceRef = useRef(false);

  const [usersListData, setUsersListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [paginationCount, setPaginationCount] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [selectedUser, setSelectedUser] = useState("");
  const [selectUserGroup, setSelectUserGroup] = useState("");
  const [selectRfid, setSelectRfid] = useState("");
  const [selectEditValue, setSelectEditValue] = useState("");
  const [selectedUserGroup, setSelectedUserGroup] = useState("");
  const [userGroupList, setUserGroupList] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState([]);
  const [searchParam, setSearchParam] = useState(null);

  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [listNotes, setListNotes] = useState([]);
  const [checkData, setCheckData] = useState([]);

  const [startValue, setStartValue] = useState();
  const [endValue, setEndValue] = useState();
  const [status, setStatus] = useState("Aktiv");

  const listNotificationPreferenceResponseData = useSelector(
    (state) => state.importExport.listNotificationPreferenceResponseData
  );
  const userListData = useSelector((state) => state.importExport.userListData);

  const dateFormat = "MM/DD/YYYY";

  const setIds = (id) => {
    let numString = String(id);
    return numString;
  };

  const onCheckAllChange = (e) => {
    let localNotificationData = notificationData;
    let localNotificationArray = [];
    let newPreferences = [];
    for (let i = 0; i < localNotificationData.length; i++) {
      let newPreference = {};
      localNotificationArray.push(localNotificationData[i].value);
      newPreference.notification_id = localNotificationData[i].value;
      newPreference.label = localNotificationData[i].label;
      newPreferences.push(newPreference);
    }

    let allChoice = {
      notification_id: checkData._id,
      label: checkData.label,
    };

    newPreferences.push(allChoice);

    let sendList = localNotificationArray;
    sendList.push(checkData._id);
    setListNotes(e.target.checked ? sendList : []);

    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const checkChanges = (list) => {
    setListNotes(list);
    setIndeterminate(!!list.length && list.length < notificationData.length);
    setCheckAll(list.length === notificationData.length);
  };

  useEffect(() => {
    let sendDataObjects = [];
    let useNoteficationData = notificationData;

    for (let i = 0; i < useNoteficationData.length; i++) {
      for (let j = 0; j < listNotes.length; j++) {
        if (useNoteficationData[i].value === listNotes[j]) {
          let sendDataObject = {};
          sendDataObject.id = useNoteficationData[i].value;
          sendDataObject.label = useNoteficationData[i].label;

          sendDataObjects.push(sendDataObject);
        }
      }
    }

    setSelectedNotification(sendDataObjects);
  }, [checkAll, listNotes]);

  const handleTableChange = (selectedPagination) => {
    const pager = { ...pagination };
    pager.current = selectedPagination.current;
    const page = selectedPagination.current;
    setLoading(true);
    setPagination(pager);
    dispatch(userList(page));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCheckAll(false);
    setIndeterminate(false);
  };

  const handleOk = () => { 
    
    updateRfid(selectEditValue, selectRfid, selectedUserGroup)
      .then((res) => {
        setIsModalVisible(false);
      })
      .then(
        updatePreferences(selectEditValue, selectedNotification).then((res) => {
          dispatch(userList());
        })
      );
  };

  const onGroupChange = (value) => {
    setSelectedUserGroup(value);
  };

  const onInputChange = (e) => {
    let inputString = e.target.value;
    setSearchParam(inputString);
  };

  const onBlur = () => {
    console.log("blur");
  };

  const onFocus = () => {
    console.log("focus");
  };

  const resetFunction = () => {
    setLoading(true);
    dispatch(userList());
  };

  const convertDateMonth = (data) => {
    if (data.length < 2) {
      return `0${data}`;
    } else {
      return data;
    }
  };

  const searchQueryFunction = () => {
    setLoading(true);

    let startDate = null;
    let endDate = null;

    if (startValue !== undefined && startValue !== null) {
      startDate = `${convertDateMonth(
        `${startValue._d.getMonth() + 1}`
      )}/${convertDateMonth(
        `${startValue._d.getDate()}`
      )}/${startValue._d.getFullYear()}`;
    }

    if (endValue !== undefined && endValue !== null) {
      endDate = `${convertDateMonth(
        `${endValue._d.getMonth() + 1}`
      )}/${convertDateMonth(
        `${endValue._d.getDate()}`
      )}/${endValue._d.getFullYear()}`;
    }
    searchUser(searchParam, startDate, endDate).then((res) => {
      let newData = res.data;
      setLoading(false);

      if (newData) {
        const list = newData;

        let dataAry = [];

        for (let i = 0; i < list.length; i++) {
          let newList = {};
          newList.knum = list[i].rfid?.card_number;
          newList.name = `${list[i].firstname ? list[i].firstname : ""} ${
            list[i].surname ? list[i].surname : ""
          }`;
          newList.group = list[i].user_group;
          newList.key = list[i]["member_no"];
          newList.mnum = setIds(list[i]["member_no"]);
          newList.member_no = list[i]["member_no"];
          newList.isActive = list[i]["isActive"];
          newList.push_notification = list[i]["push_notification"];
          newList.rfid = list[i].rfid;
          newList.flag = list[i].flag;          
          newList.date = list[i].entry_date;
          newList.member_no = list[i]["member_no"];
          newList.isActive = list[i]["isActive"];
          newList.push_notification = list[i]["push_notification"];
          newList.rfid = list[i].rfid;
          newList.flag = list[i].flag;       
          dataAry.push(newList);
        }
        
        setUsersListData(dataAry);
       
       
      } else {
        message.error(newData.message);
      }
    });
  };

  useEffect(() => {
    if (didMountNotificationPreferenceRef.current) {
      if (listNotificationPreferenceResponseData) {
        if (listNotificationPreferenceResponseData.status === 200) {
          console.log("----");

          console.log(listNotificationPreferenceResponseData);
          console.log("----");

          const data = listNotificationPreferenceResponseData.data;
          if (data) {
            const notificationList = data;
            let notificationAry = [];

            for (let i = 0; i < notificationList.length - 1; i++) {
              let newList = {};
              newList.label = notificationList[i].label;
              newList.value = notificationList[i]._id;
              newList.isChecked = false;
              notificationAry.push(newList);
            }
            let checkAllBox = notificationList.filter(
              (item) => item.label === "Alle Benachrichtigungen"
            );
            setCheckData(checkAllBox[0]);
            setNotificationData(notificationAry);
          } else {
            message.error(data.message);
          }
        } else {
          message.error(listNotificationPreferenceResponseData.message);
        }
      }
    } else {
      dispatch(notificationPreference(1));
      didMountNotificationPreferenceRef.current = true;
    }
  }, [listNotificationPreferenceResponseData]);

  useEffect(() => { 
    if (didMountUserListRef.current) {
      setLoading(false);
      if (userListData) {
        if (userListData.status === 200) {
          const data = userListData.data;
          if (data) {
            const pager = { ...pagination };
            let page = pager.current;
            if (page === undefined) {
              page = 1;
            }
            const list = data.data;
            let dataAry = [];
            
            for (let i = 0; i < list.length; i++) { 
              let newList = {};
              newList.knum = list[i].rfid?.card_number;
              newList.name = `${list[i].firstname ? list[i].firstname : ""} ${
                list[i].surname ? list[i].surname : ""
              }`;
              newList.group = list[i].user_group;
              newList.isActive = list[i].isActive;
              newList.key = list[i]["member_no"];
              newList.mnum = setIds(list[i]["member_no"]);
              newList.date = list[i].entry_date;
              newList.rfid = list[i].rfid;
              newList.member_no = list[i]["member_no"];
              newList.push_notification = list[i]["push_notification"];
              newList.flag = list[i].flag;
              dataAry.push(newList);
            }
            
            setUsersListData(dataAry);
            const paginationData = { ...pagination };
            // Read total count from server

            // const totalPage = Math.ceil(data.count / 100);
            const totalPage = data.count;

            setPaginationCount(totalPage);
            // paginationData.total = Math.ceil(data.count / 100);
            setPagination(paginationData);
          } else {
            message.error(data.message);
          }
        } else {
          message.error(userListData.message);
        }
      }
    } else {
      dispatch(userList(1));
      createUserGroupList();
      didMountUserListRef.current = true;
    }
  }, [userListData, isActive]);

  const createUserGroupList = () => {
    userGroup().then((res) => {
      setUserGroupList(res.data);
    });
  };

  const onChangeStatus = async (checked) => {
    
    let data = {
      member_no: selectEditValue,
      isActive: checked,
    };
    try {
      const { Authorization } = autheader();

      let response = await fetch(
        `${process.env.REACT_APP_SERVER_URI}/${API_VERSION}/update-membership-status`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        setIsActive(checked);
        dispatch(userList());
      }
    } catch (error) {
      throw error;
    }
  };

  const editHandler = (e, y) => {
    setIsModalVisible(true);
    setSelectedUser(y.name);
    setSelectedUserGroup(y.group);
    setSelectEditValue(y.key);
    // console.log(y);
    let newUserList = usersListData;

    let userID = "";
    for (let i = 0; i < newUserList.length; i++) { 
      if (newUserList[i].member_no === y.key) { console.log("mnm,hmk")
        setIsActive(newUserList[i].isActive);
        userID = newUserList[i].rfid?._id;
        console.log("userID"+userID)
        let notificationArr = newUserList[i].push_notification;
        let baseNotifications = notificationData;

        let notificationChecked = [];
        let allnotificationids = [];
        let checkedids = [];

        for (let j = 0; j < notificationArr.length; j++) {
          notificationChecked.push(notificationArr[j].label);
        }

        for (let j = 0; j < notificationArr.length; j++) {
          checkedids.push(notificationArr[j].id);
        }

        for (let j = 0; j < baseNotifications.length; j++) {
          allnotificationids.push(baseNotifications[j].value);
        }

        // Setting default values for edit modal
        let newArr = [];
        if (
          notificationChecked.includes("Alle Benachrichtigungen") ||
          notificationChecked.length >= 3
        ) {
          newArr = allnotificationids.map((item) => item);
          newArr.push(checkData._id);
          setCheckAll(true);
        } else if (
          notificationChecked.includes("Alle Benachrichtigungen") === false &&
          notificationChecked.length < 3 &&
          notificationChecked.length !== 0
        ) {
          newArr = checkedids.map((item) => item);
          setIndeterminate(true);
        }
        setListNotes(newArr);
        let userStatus = newUserList[i].flag ? "Aktiv" : "Inaktiv";
        setStatus(userStatus);
      }
    }
    
    setSelectRfid(userID);
  };

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const columns = [
    {
      title: "Mitglied",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mitgliednummer",
      dataIndex: "mnum",
      key: "mnum",
    },
    {
      title: "Eintrittsdatum",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Nutzergruppe",
      dataIndex: "group",
      key: "group",
    },
    {
      title: "Kartennummer",
      dataIndex: "knum",
      key: "knum",
    },
    {
      title: "Bearbeiten",
      dataIndex: "operation",
      render: (text, record) =>
        record.knum === undefined ? (
          <a disabled>Bearbeiten</a>
        ) : (
          <a onClick={(e) => editHandler(e, record)}>Bearbeiten</a>
        ),
    },
  ];
  return (
    <div>
      <h2 className="text-align-center">
        <Text strong>Management Mitglieder</Text>
      </h2>
      <Row>
        <Col>
          <Title level={4}>Alle Datensätze</Title>
          <Space direction="horizontal">
            <Text strong>Eintrittsdatum</Text>
            <DatePicker
              value={startValue}
              format={dateFormat}
              onChange={(val) => setStartValue(val)}
            />
            <DatePicker
              value={endValue}
              format={dateFormat}
              onChange={(val) => setEndValue(val)}
            />
          </Space>
        </Col>
      </Row>
      <Row className="mt-32 mb-16">
        <Col span={8}>
          <Input placeholder="Suchen..." allowClear onChange={onInputChange} />
        </Col>
        <Col span={3} offset={1}>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={searchQueryFunction}
          >
            Suchen
          </Button>
        </Col>
        <Col span={3}>
          <Button type="primary" onClick={resetFunction}>
            Zurücksetzen
          </Button>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <Table dataSource={usersListData} columns={columns} />
      </Spin>
      <Modal
        title="Fenster zum Bearbeiten von Benutzerdaten"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Speichern"
        cancelText="Abbrechen"
      >
        <Text strong>Nutzername: </Text>
        <Text>{selectedUser}</Text>
        <Divider />
        <Text strong>Gruppe bearbeiten: </Text>
        <br />
        <Select
          value={selectedUserGroup}
          style={{ width: 200 }}
          placeholder="Wählen Sie Benutzergruppe"
          optionFilterProp="children"
          onChange={onGroupChange}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {userGroupList.map((v) => (
            <Option key={v._id} value={v.group_name}>
              {v.group_name}
            </Option>
          ))}
        </Select>
        <Divider />
        <Text strong>Einstellungen bearbeiten: </Text>
        <CheckboxGroup
          options={notificationData}
          value={listNotes}
          onChange={checkChanges}
        />
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          {checkData.label}
        </Checkbox>
        <Divider />
        <Text strong>Ist aktiv: </Text>
        <Switch checked={isActive} onChange={onChangeStatus} />

        {/* {
              notificationData.map(notification => (
                <div key={notification.value}>
                  <Checkbox
                  checked={notification.isChecked}
                  value={notification.value}
                  onChange= {checkboxChange}
                >
                  {notification.label}
                </Checkbox>
                </div>
              ))
            } */}
      </Modal>
    </div>
  );
};

export default MembershipManagement;
