import {
  UPLOAD_USER_FILE_RESPONSE,
  SET_SELECTED_USERS_FOR_PRINT,
  SET_SIDEBAR_ITEM_KEY,
  EXPORT_FILE_RESPONSE,
  FILE_UPLOAD_HISTORY_RESPONSE,
  DOWNLOAD_PDF_RESPONSE,
  USER_LIST_RESPONSE,
  LIST_NOTIFICATION_PREFERENCE_RESPONSE,
  SEND_NOTIFICATIONS,
  FILTERED_NOTIFICATION_RESPONSE
} from './action-type';

export const uploadUsersFileResponse = payload => ({
  type: UPLOAD_USER_FILE_RESPONSE,
  payload
});

export const setSelectedUsersForPrint = payload => {
  return {
    type: SET_SELECTED_USERS_FOR_PRINT,
    payload
  };
};

export const setSideBarItemKey = payload => {
  return {
    type: SET_SIDEBAR_ITEM_KEY,
    payload
  };
};

export const exportFileResponse = payload => {
  return {
    type: EXPORT_FILE_RESPONSE,
    payload
  };
};

export const fileUploadHistoryResponse = payload => {
  return {
    type: FILE_UPLOAD_HISTORY_RESPONSE,
    payload
  };
};

export const downloadPdfResponse = payload => {
  return {
    type: DOWNLOAD_PDF_RESPONSE,
    payload
  };
};

export const sendNotifications = payload => {
  return {
    type: SEND_NOTIFICATIONS,
    payload
  }
}

export const filteredNotificationResponse = payload => {
  return {
    type: FILTERED_NOTIFICATION_RESPONSE,
    payload
  }
}

export const userListResponse = payload => {
  return {
    type: USER_LIST_RESPONSE,
    payload
  };
};

export const listNotificationPreferenceResponse = payload => {
  return {
    type: LIST_NOTIFICATION_PREFERENCE_RESPONSE,
    payload
  };
};